/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import AddEmployeeModal from "./AddEmployeeModal";
import EditEmployeeModal from "./EditEmployeeModal";
import Employee from "./Employee";
import { URLS } from "../urls";
// import PieChartModel from "./PieChartModel";
import { fetchActionsUtil, stringifyQueryString, useCookie } from "../../utils";
import ModalLoader from "../ModalLoader";
import { AddTerminationModal } from "../termination/AddTerminationModal";
import { EditEmployeeSalaryModal } from "./EditEmployeeSalaryModal";
import { GridIcon, ListIcon, SearchIcon } from "../icons";
import { EmployeeExports } from "./EmployeeExports";
import EmployeeTable from "./EmployeeTable";
import DeleteEmployee from "./DeleteEmployee";
import { toast } from "react-toastify";
import { useDebounceQueryString, useQueryString } from "../UseQueryStrings";
import CustomPagination from "../Paginations";

const Employees = (props) => {
  const initialQueryParams = {
    limit: 10,
    page: 1,
    id: "",
    name: "",
    department: "",
  };

  const branch_id = useCookie("branch_id");

  const token = "dummy token";

  const { queryParams, updateQueryString } = useQueryString();

  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableMode, seTableMode] = useState(true);
  const [showSalary, setShowSalary] = useState(false);
  const [theQueryParams, setQueryParams] = useState({ ...initialQueryParams });
  const [returnParams, setReturnParams] = useState({
    count: 0,
    endIndex: 0,
    startIndex: 0,
  });

  const [state, setState] = useState({
    employees: [], //update this whenever you add/update employee in backend.
    editEmployee: "", // id or index of the employee which is to be edited
    editUser: "",
    deleteEmployee: "",
    showEdit: false,
    searchEmlpoyeeList: [],
    departments: [],
    employeesStatistics: {
      confirmed: 0,
      trainee: 0,
      probation: 0,
      internship: 0,
      gender: {
        Male: 0,
        Female: 0,
        Others: 0,
      },
    },
    addEmployee: false,
    openEditSalary: false,
    openTermination: false,
    employeeUserId: "",
    fullName: "",
    employee_id: "",
    jobgradeid: "",
    reload: 0,
  });

  const debouncedFilterParams = useDebounceQueryString(theQueryParams, 1000);

  useEffect(() => {
    updateQueryString(theQueryParams);
  }, [debouncedFilterParams]);

  const onEditEmployee = (_id, userId) => {
    setState((el) => ({ ...el, editEmployee: _id, editUser: userId }));
  };

  const deleteEmployee = (_id) => {
    setState((el) => ({ ...el, deleteEmployee: _id }));
  };

  const getEditEmployeeDataFromId = (id) => {
    const { employees } = state;
    let employee = {};
    if (employees && employees.length)
      employees.forEach((e) => {
        if (e && e._id === id) {
          employee = e;
          employee.jobInfo.grade =
            employee.jobInfo.grade && employee.jobInfo.grade._id;
          employee.jobInfo.workLocation =
            employee.jobInfo.workLocation && employee.jobInfo.workLocation._id;
          employee.jobInfo.department =
            employee.jobInfo.department && employee.jobInfo.department._id;
        }
      });
    return employee;
  };

  const getEditUserDataFromId = (id) => {
    const { users } = state;
    let user = {};
    if (users && users.length) {
      user = users.find((user) => user._id === id);
    }
    return user;
  };

  const updateEmployeesList = (employee) => {
    let { employees } = state;
    const id = employee && employee._id;
    let isExists = false;
    for (let index = employees.length - 1; index >= 0; --index) {
      if (employees[index]._id === id) {
        employees.splice(index, 1);
        employees.splice(index, 0, employee);
        isExists = true;
        break;
      }
    }
    if (isExists) {
      setState((el) => ({
        ...el,
        employees: employees,
        searchEmlpoyeeList: employees,
      }));
    } else {
      const updatedEmployees = employees.concat(employee);
      setState((el) => ({
        ...el,
        employees: updatedEmployees,
        searchEmlpoyeeList: updatedEmployees,
      }));
    }
  };

  const componentWillMount = async () => {
    try {
      setIsLoading(true);

      const { employeesStatistics } = state;

      const userData = fetchActionsUtil(
        `${URLS.backendUsers}/getAllUsers/${branch_id}`,
        "POST",
        token
      );
      const employeeData = fetchActionsUtil(
        `${URLS.backendEmployees}/get/${branch_id}?${stringifyQueryString(
          queryParams
        )}`,
        "GET",
        token
      );
      const departmentData = fetchActionsUtil(
        `${URLS.backendDepartments}/get/${branch_id}`,
        "GET",
        token
      );

      const [departmentResult, userResult, employeeResult] = await Promise.all([
        departmentData,
        userData,
        employeeData,
      ]);

      if (employeeResult?.employees?.length > 0) {
        employeeResult.employees.forEach((employee) => {
          if (employee.jobInfo.status === "Confirmed")
            employeesStatistics.confirmed += 1;
          else if (employee.jobInfo.status === "Trainee")
            employeesStatistics.trainee += 1;
          else if (employee.jobInfo.status === "Probation")
            employeesStatistics.probation += 1;
          else if (employee.jobInfo.status === "Internship")
            employeesStatistics.internship += 1;

          if (employee.personalInfo.gender)
            employeesStatistics.gender[employee.personalInfo.gender] += 1;
        });
      }

      setState((el) => ({
        ...el,
        users: userResult.users,
        employees: employeeResult.employees,
        searchEmlpoyeeList: employeeResult.employees,
        departments: departmentResult.departments,
        employeesStatistics,
      }));
      setReturnParams({
        count: employeeResult.count,
        endIndex: employeeResult.endIndex,
        startIndex: employeeResult.startIndex,
      });
    } catch (error) {
      console.log(error);
      toast.error("Fetching Error !!!");
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setQueryParams((el) => ({ ...el, page: 1, [name]: value }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      componentWillMount();
    }

    return () => {
      isMounted = false;
    };
  }, [branch_id, queryParams]);

  const handleDeleteEmployee = () => {
    let employees = state.employees;
    const employeeId = state.deleteEmployee;
    const token = "dummy token";

    if (process.env.REACT_APP_HAS_INVEX === "true") {
      fetch(`${URLS.mainSiteBackend}/users/Employee/${employeeId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ EmployeeID: employeeId }),
      }).then((res) => {
        if (res.ok) {
          fetch(`${URLS.backendEmployees}/delete`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ employeeId }),
          })
            .then((res) => res.json())
            .then((result) => {
              employees = employees.filter(
                (employee) => employee._id !== employeeId
              );
              setState((el) => ({
                ...el,
                employees,
                searchEmlpoyeeList: employees,
              }));
            });
        } else {
          res.json().then((responseJson) => {
            console.log(responseJson);
            alert(responseJson.message);
          });
        }
      });
    } else {
      fetch(`${URLS.backendEmployees}/delete`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ employeeId }),
      })
        .then((res) => res.json())
        .then((result) => {
          employees = employees.filter(
            (employee) => employee._id !== employeeId
          );
          setState((el) => ({
            ...el,
            employees,
            searchEmlpoyeeList: employees,
          }));
        });
    }
    setOpenDelete(false);
  };

  const OnFullName = (employee) => {
    const name =
      employee?.personalInfo?.name?.first +
      " " +
      employee?.personalInfo?.name?.last;
    setState((el) => ({ ...el, fullName: name }));
  };

  const searchEmployee = async () => {
    try {
      setIsLoading(true);

      const employeeData = await fetchActionsUtil(
        `${URLS.backendEmployees}/get/${branch_id}?${stringifyQueryString(
          queryParams
        )}`,
        "GET",
        token
      );

      setState((el) => ({
        ...el,
        employees: employeeData.employees,
        searchEmlpoyeeList: employeeData.employees,
      }));

      setReturnParams({
        count: employeeData.count,
        endIndex: employeeData.endIndex,
        startIndex: employeeData.startIndex,
      });
    } catch (error) {
      console.log(error);
      toast.error("Fetching Error !!!");
    } finally {
      setIsLoading(false);
    }
  };
  const dropDownFunction = (qty) => {
    setQueryParams((el) => ({ ...el, limit: qty }));
  };

  const {
    editEmployee,
    editUser,
    searchEmlpoyeeList,
    departments,
    employeesStatistics,
    openEditSalary,
    openTermination,
    employeeUserId,
    fullName,
    employee_id,
    jobgradeid,
    showEdit,
  } = state;
  const editEmployeeData = getEditEmployeeDataFromId(editEmployee);
  const editUserData = getEditUserDataFromId(editUser);

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow" />
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div>
            {/*<!-- Page Title -->*/}

            <div className="add_vacancy  mb-3 d-flex border bg-white align-items-center justify-content-between">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column container ">
                  <h3 className="mt-1">Employee</h3>
                  <p className="pb-0 secondary-text">
                    All Employee status and dates
                  </p>
                </div>

                <div className="d-flex justify-content-end align-items-baseline w-100 mr-3">
                  <div className="d-flex align-items-center">
                    <div
                      className={`mx-2 p-cursor ${
                        tableMode
                          ? "text-ash-main"
                          : "blue-color text-primary-main"
                      }`}
                      onClick={() => seTableMode(false)}
                    >
                      <GridIcon color={tableMode ? null : "#2463ae"} />{" "}
                      <b>Grid</b>
                    </div>
                    <div
                      className={`mx-2 p-cursor ${
                        tableMode
                          ? "blue-color text-primary-main"
                          : "text-ash-main"
                      }`}
                      onClick={() => seTableMode(true)}
                    >
                      <ListIcon color={tableMode ? "#2463ae" : null} />{" "}
                      <b>List</b>
                    </div>
                  </div>

                  <div className="mx-3">
                    <EmployeeExports
                      Employees={searchEmlpoyeeList}
                      showSalary={showSalary}
                    />
                  </div>

                  <button
                    className="btn add-btn mx-3"
                    onClick={() =>
                      setState((el) => ({ ...el, addEmployee: true }))
                    }
                  >
                    <i className="fa fa-plus"></i>Add Employee
                  </button>
                </div>
              </div>
            </div>

            <div className="my-4">
              <div className="d-flex justify-content-start mx-4">
                <div>
                  <Link to={`${URLS.dashboard}`}>
                    <b>Dashboard</b>
                  </Link>
                </div>
                <span className="mx-2">/</span>
                <div>
                  <p className="text-muted">Employees</p>
                </div>
              </div>
            </div>

            {/*<!-- /Page Title -->*/}
            {/*<!-- Leave Statistics -->*/}
            <section className="stats mx-4">
              <div className="stat">
                <div className="icon icon-1">
                  <i class="fa fa-cubes"></i>
                </div>
                <div className="details">
                  <p>{employeesStatistics?.confirmed}</p>
                  <span className="dashboard-links">Confirmed </span>
                </div>
              </div>

              <div className="stat">
                <div className="icon icon-3">
                  <i class="fa fa-cubes"></i>
                </div>
                <div className="details">
                  <p>{employeesStatistics?.trainee}</p>
                  <span className="dashboard-links">Trainee </span>
                </div>
              </div>

              <div className="stat">
                <div className="icon">
                  <i class="fa fa-cubes"></i>
                </div>
                <div className="details">
                  <p>{employeesStatistics?.probation}</p>
                  <span className="dashboard-links">Probation</span>
                </div>
              </div>

              <div className="stat">
                <div className="icon">
                  <i class="fa fa-cubes"></i>
                </div>
                <div className="details">
                  <p>{employeesStatistics?.internship}</p>
                  <span className="dashboard-links">Internship</span>
                </div>
              </div>
            </section>
            {/*<!-- /Leave Statistics -->*/}
            {/* <div className="d-flex justify-content-center">
              <div>
                <PieChartModel
                  data={[
                    {
                      name: "Male",
                      value: employeesStatistics["gender"]["Male"],
                    },
                    {
                      name: "Female",
                      value: employeesStatistics["gender"]["Female"],
                    },
                    {
                      name: "Others",
                      value: employeesStatistics["gender"]["Others"],
                    },
                  ]}
                />
              </div>
            </div> */}
            {/*<!-- Search Filter -->*/}
            <div className="row m-3 mb-0">
              <div className="col-sm-6 col-md-4">
                <div className="search-area">
                  <SearchIcon color="#94A3B8" />
                  <Form.Control
                    placeholder="Search employee ID"
                    aria-label="Search employee ID"
                    value={theQueryParams.id}
                    name="id"
                    onChange={onChangeInput}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="search-area">
                  <SearchIcon color="#94A3B8" />
                  <Form.Control
                    placeholder="Search employee name"
                    aria-label="Search employee name"
                    value={theQueryParams.name}
                    onChange={onChangeInput}
                    name="name"
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <Form.Control
                  as="select"
                  value={theQueryParams.department}
                  onChange={onChangeInput}
                  name="department"
                >
                  <option value="">Select Department</option>
                  {departments && departments.length
                    ? departments.map((department) => {
                        return (
                          <option value={department._id}>
                            {department.name}
                          </option>
                        );
                      })
                    : null}
                </Form.Control>
              </div>
              <button
                className="btn btn-primary col-sm-6 col-md-1"
                onClick={searchEmployee}
              >
                Search
              </button>
            </div>

            {tableMode ? (
              <div className="d-flex justify-content-end mx-4 mb-4">
                <div className="">
                  <label>Show Salary</label>
                  <div
                    className="onoffswitch ml-0"
                    onClick={(e) => {
                      setShowSalary(!showSalary);
                    }}
                  >
                    <input
                      type="checkbox"
                      name="onoffswitch"
                      className="onoffswitch-checkbox"
                      checked={showSalary}
                    />
                    <label className="onoffswitch-label">
                      <span className="onoffswitch-inner"></span>
                      <span className="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </div>
            ) : null}

            {searchEmlpoyeeList?.length > 0 ? (
              <div>
                {tableMode ? (
                  <div className="mx-4">
                    <EmployeeTable
                      showSalary={showSalary}
                      searchEmlpoyeeList={searchEmlpoyeeList}
                      setState={(el) => setState(el)}
                      onEditEmployee={onEditEmployee}
                      deleteEmployee={deleteEmployee}
                      OnFullName={OnFullName}
                      setOpenDelete={setOpenDelete}
                      startIndex={returnParams.startIndex}
                    />
                  </div>
                ) : (
                  <div className="row mx-3">
                    {searchEmlpoyeeList?.map((employee) => (
                      <Employee
                        key={employee._id}
                        onEdit={(_id, userId) => onEditEmployee(_id, userId)}
                        onDelete={() => deleteEmployee(employee._id)}
                        openEdit={() =>
                          setState((el) => ({
                            ...el,
                            showEdit: true,
                          }))
                        }
                        employee={employee}
                        setOpenDelete={setOpenDelete}
                      />
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <h3 className="text-center w-100">No employee found</h3>
            )}
            <div className="my-4">
              <CustomPagination
                setQueryParams={setQueryParams}
                startIndex={returnParams.startIndex}
                endIndex={returnParams.endIndex}
                count={returnParams.count}
                limit={queryParams.limit}
                page={queryParams.page}
                dropDownFunction={dropDownFunction}
              />
            </div>
          </div>
          {/*<!-- /Page Content -->*/}
          <ModalLoader show={isLoading} />
          {/*<!-- Add Employee Modal -->*/}
          {state?.addEmployee ? (
            <AddEmployeeModal
              mode="add"
              show={state.addEmployee}
              updateEmployeesList={updateEmployeesList}
              hindeIt={() => setState((el) => ({ ...el, addEmployee: false }))}
            />
          ) : null}
          {/*<!-- /Add Employee Modal -->*/}

          {/*<!-- Edit Employee Modal -->*/}

          {showEdit && editEmployeeData && editEmployee && editUser ? (
            <EditEmployeeModal
              showEdit={showEdit}
              mode="edit"
              employee={editEmployeeData}
              user={editUserData}
              editUser={editUser}
              updateEmployeesList={updateEmployeesList}
              employee_id={editEmployee}
              hindeIt={() => setState((el) => ({ ...el, showEdit: false }))}
            />
          ) : null}

          {/* {pass correct employee in edit employee modal. use editEmployee} */}
          {/*<!-- /Edit Employee Modal -->*/}
          {openTermination && (
            <AddTerminationModal
              show={openTermination}
              handleClose={() =>
                setState((el) => ({
                  ...el,
                  openTermination: false,
                  reload: 1 + Math.random() * (10000 - 1),
                }))
              }
              name={fullName}
              userId={employeeUserId}
            />
          )}

          {openEditSalary && (
            <EditEmployeeSalaryModal
              show={openEditSalary}
              handleClose={() =>
                setState((el) => ({ ...el, openEditSalary: false }))
              }
              user_id={employee_id}
              name={fullName}
              jobgradeid={jobgradeid}
            />
          )}

          {/*<!-- Delete Employee Modal -->*/}
          {openDelete && (
            <DeleteEmployee
              handleDeleteEmployee={handleDeleteEmployee}
              show={openDelete}
              handleClose={setOpenDelete}
            />
          )}
          {/*<!-- /Delete Employee Modal -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
};

export default Employees;
