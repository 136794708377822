import React, { Fragment, useEffect, useState } from "react";
import { URLS } from "../urls";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
// import Select from "react-select";
// import CurrencyInput from "react-currency-input-field";
import { branch_id, allMonths } from "../../utils";

const SentToAccount = ({
  Amount,
  payrollDate,
  employeeType,
  deductionItems,
  show,
  onShow,
}) => {
  const [state, setState] = useState({
    buttonText: "Submit",
    isWaiting: false,
    Amount: Amount,
    applyDate: moment(new Date()).format("DD/MM/YYYY"),
    staff: "",
    departments: [],
    users: [],
    comment: "",
    appliedDate: "",
    description: `${allMonths[payrollDate.payrollMonth - 1]} ${
      payrollDate.payrollYear
    } Salary`,
    payrollMonth: payrollDate.payrollMonth,
    payrollYear: payrollDate.payrollYear,
    employeeType: employeeType,
    deductionItems: deductionItems,
  });

  useEffect(() => {
    let IsFetching = true;

    if (IsFetching) {
      getDepartments();
      getEmployees();
    }

    return () => {
      IsFetching = false;
    };
  }, []);

  const getDepartments = async () => {
    // await waitFor(5000);
    if (process.env.REACT_APP_HAS_INVEX === "true") {
      let response = await fetch(`${URLS.mainSiteBackend}/users/departments`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();

        throw new Error(response.message);
      }

      const { data } = await response.json();

      setState((el) => ({
        ...el,
        departments: data?.departments,
      }));
    }
  };

  const getEmployees = async () => {
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const emp = result.employees.map((em) => ({
          label: `${
            em.personalInfo.name.first ? em.personalInfo.name.first : ""
          } ${em.personalInfo.name.middle ? em.personalInfo.name.middle : ""} ${
            em.personalInfo.name.last ? em.personalInfo.name.last : ""
          }`,
          value: JSON.stringify({
            id: em._id,
            name: `${
              em.personalInfo.name.first ? em.personalInfo.name.first : ""
            } ${
              em.personalInfo.name.middle ? em.personalInfo.name.middle : ""
            } ${em.personalInfo.name.last ? em.personalInfo.name.last : ""}`,
          }),
        }));
        setState((el) => ({
          ...el,
          employees: emp,
        }));
      });
  };

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${URLS.mainSiteBackend}/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    setState((el) => ({
      ...el,
      users: data?.users,
    }));
  };

  const onChangeInputs = (e) => {
    const { name, value } = e.target;
    setState((el) => ({
      ...el,
      [name]: value,
    }));
  };

  const submit = (e) => {
    e.preventDefault();
    setState((el) => ({
      ...el,
      buttonText: "Submitting ...",
      isWaiting: true,
    }));
    const {
      description,
      comment,
      Amount,
      staff,
      payrollMonth,
      payrollYear,
      employeeType,
      deductionItems,
    } = state;

    const requsion = {
      title: `${allMonths[payrollMonth - 1]} ${payrollYear} Salary`,
      description,
      type: "Salary",
      amount: Amount,
      requestto: staff,
      status: "Pending",
      regdate: moment(new Date()).format("YYYY-MM-DD"),
      requestby: staff,
      vendor: "",
      comment,
      filledFormData: "",
      loanDetails: JSON.stringify({
        payrollMonth,
        payrollYear,
        Amount,
        deductionItems: deductionItems,
      }),
    };

    const formData = new FormData();
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(requsion)) {
      formData.append(key, value);
    }
    // console.log(requsion);
    const token = "dummy token";

    if (process.env.REACT_APP_HAS_INVEX === "true") {
      fetch(`${URLS.mainSiteBackend}/requisition/create`, {
        method: "POST",
        // credentials: "include",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      }).then((res) => {
        if (res.ok) {
          res.json().then((result) => {
            console.log(result);

            fetch(`${URLS.backendSalary}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
              },
              body: JSON.stringify({
                Amount,
                status: "Pending",
                paymentsStatus: "Not paid",
                requestid: result?.data?.requisition?.requestid,
                branch_id,
                payrollMonth,
                payrollYear,
                employeeType,
              }),
            }).then((el) => {
              if (el.ok) {
                el.json().then((response) => {
                  // console.log(response);

                  toast("Request Sent Sucessfully.", {
                    type: "success",
                    position: "top-right",
                  });
                  setState((el) => ({
                    ...el,
                    buttonText: "Submit",
                    isWaiting: false,
                    staff: "",
                    departments: [],
                    users: [],
                    comment: "",
                    appliedDate: "",
                    title: "",
                    description: "",
                    Amount: 0,
                    employeeType: "",
                    payrollMonth: "",
                    payrollYear: "",
                  }));
                  onShow();
                });
              } else {
                el.json().then((json) => {
                  console.log(json);

                  toast("Error occurred, Check the form again.", {
                    type: "error",
                    position: "top-right",
                  });
                  setState((el) => ({
                    ...el,
                    buttonText: "Submit",
                    isWaiting: false,
                  }));
                });
              }
            });
          });
        } else {
          res.json().then((responseJson) => {
            console.log(responseJson);
            toast("Error occurred, Check the form again.", {
              type: "error",
              position: "top-right",
            });
            setState((el) => ({
              ...el,
              buttonText: "Submit",
              isWaiting: false,
            }));
          });
        }
      });
    }
  };

  const {
    departments,
    users,
    staff,
    buttonText,
    description,
    comment,
    isWaiting,
  } = state;

  return (
    <Modal show={show} size="md" onHide={onShow}>
      <div>
        <div>
          <Modal.Header closeButton>
            <Modal.Title>Request for approval</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={submit}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Description <span className="text-danger">*</span> :
                </Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="description"
                    value={description}
                    onChange={onChangeInputs}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter your detail description"
                    rows={4}
                    required
                  />
                </label>
              </Form.Group>

              <div className="d-flex align-items-center my-4">
                <h4 className="pr-2">Send Request To</h4>
                <hr className="flex-grow-1 m-0" />
              </div>

              <div className="row gap-3">
                <Form.Group className="col mb-3">
                  <Form.Label>
                    Department <span className="text-danger">*</span> :
                  </Form.Label>
                  <select
                    className="form-control"
                    onChange={(e) => getUsersInDepartments(e.target.value)}
                    required
                  >
                    <option value="">Select Recipient's Department</option>
                    {departments &&
                      departments.map((el, index) => (
                        <option key={index} value={el.Department}>
                          {el.Department}
                        </option>
                      ))}
                  </select>
                </Form.Group>

                {users.length > 0 && (
                  <Form.Group className="col mb-3">
                    <Form.Label>
                      Recipient <span className="text-danger">*</span> :
                    </Form.Label>
                    <select
                      name="staff"
                      className="form-control"
                      value={staff}
                      onChange={onChangeInputs}
                      required
                    >
                      <option value="">Select Recipient </option>
                      {users.map((el, index) => (
                        <Fragment key={index}>
                          <option value={el.Staff_ID}>{el.Name}</option>
                        </Fragment>
                      ))}
                    </select>
                  </Form.Group>
                )}
              </div>

              <Form.Group className="mb-3">
                <Form.Label>Comment :</Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="comment"
                    value={comment}
                    onChange={onChangeInputs}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter a comment"
                    rows={4}
                  />
                </label>
              </Form.Group>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  disabled={isWaiting}
                >
                  {buttonText}
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default SentToAccount;
