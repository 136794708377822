import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import currency from "currency.js";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import { Col, Form, Row } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import DocumentModal from "../employees/DocumentModal";
import AddJobGradeModal from "../employees/AddJobGradeModal";
import AddLocationModal from "../employees/AddLocationModal";
import AddEmployeeHMOModal from "../employees/AddEmployeeHMOModal";
import AddLifeInsuranceModal from "../employees/AddLifeInsuranceModal";
import "../../assets/css/date-picker.css";
import Datepicker from "react-datetime";
import moment from "moment";
import EmployeeSkills from "../employees/EmployeeSkills";
import EmployeeTraining from "../employees/EmployeeTraining";
import Multiselect from "../employees/Multiselect";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import Project from "../projects/Project";
import EditProjectModal from "../projects/EditProjectModal";
import AllTypeTasksEmployee from "../tasks/AllTypeTasksEmployee";
import { toast } from "react-toastify";
import { useCookie } from "../../utils";
import CurrencyInput from "react-currency-input-field";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Profile = (props) => {
  const branch_id = useCookie("branch_id");

  const [state, setState] = useState({
    show_add_job: false,
    show_add_location: false,
    show_add_employeeHMO: false,
    show_add_lifeInsurance: false,
    _id: null,
    displayImageSrc: null,
    displayResumeSrc: null,
    pdfPageNumber: 1,
    personalInfo: {
      name: {
        first: "",
        last: "",
        middle: "",
      },
      address: {
        apartment: "",
        street: "",
        city: "",
        state: "",
        zip: "",
      },
      phone: {
        home: "",
        alternate: "",
      },
      email: "",
      birth: "",
      govtId: "",
      martial: "",
      spouse: {
        name: "",
        employer: "",
        phone: "",
      },
      prevEmployee: {
        employee: "",
        phone: "",
      },
    },
    jobInfo: {
      title: "",
      employeeId: "",
      grade: "",
      supervisor: [],
      department: "",
      workLocation: "",
      email: "",
      workPhone: "",
      cellPhone: "",
      joinDate: "",
      startDate: "",
      salary: "",
      status: "",
      salaryBasis: "",
      contract: "",
      contractType: "",
      workLocationBranch: "",
    },
    emergencyInfo: {
      name: {
        first: "",
        last: "",
        middle: "",
      },
      address: {
        apartment: "",
        street: "",
        city: "",
        state: "",
        zip: "",
      },
      phone: {
        primary: "",
        secondary: "",
      },
      relation: "",
      guarantor: {
        title: "",
        name: "",
        phone: "",
        address: "",
      },
      referee: {
        title: "",
        name: "",
        phone: "",
        address: "",
      },
      employeeHMO: null,
      lifeInsurance: null,
      image: "",
      resume: "",
      certificate: "",
    },
    educationInfo: [
      {
        institution: "",
        subject: "",
        startDate: "",
        completeDate: "",
        degree: "",
        grade: "",
        certificate: "",
      },
    ],
    jobGrades: [],
    contracts: [],
    locations: [],
    departments: [],
    employeeHMOs: [],
    lifeInsurances: [],
    educationCertificateFiles: [
      {
        file: null,
      },
    ],
    supervisors: [],
    projects: [],
    editProject: "",
    deleteProject: "",
    clientList: [],
    employeeList: [],
    projectTasks: [],
    collaborationUrl: process.env.REACT_APP_COLLABORATION_FRONTEND,
    typeOfSalary: "",
    branchesLocations: [],
  });

  const componentWillMount = () => {
    const id = window.location.pathname.split("/")[3];
    const token = "dummy token";

    fetch(`${URLS.backendEmployees}/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log("result => ", result);
        setState((el) => ({
          ...el,
          _id: result.employee?._id,
          personalInfo: {
            ...el.personalInfo,
            ...result.employee?.personalInfo,
          },
          emergencyInfo: result.employee?.emergencyInfo
            ? result.employee?.emergencyInfo
            : el.state.emergencyInfo,
          educationInfo: result.employee?.educationInfo,
        }));
        let jobInfoTemp = result.employee?.jobInfo;

        if (jobInfoTemp) {
          jobInfoTemp.grade = jobInfoTemp?.grade && jobInfoTemp?.grade?._id;
          jobInfoTemp.workLocation =
            jobInfoTemp?.workLocation && jobInfoTemp?.workLocation?._id;
          jobInfoTemp.department =
            jobInfoTemp?.department && jobInfoTemp?.department?._id;

          setState((el) => ({
            ...el,
            jobInfo: jobInfoTemp,
          }));
        }

        const educationInfo = result.employee?.educationInfo;
        const educationInfoLength = educationInfo?.length;
        let newEducationCertificateFiles = [];
        for (let index = 0; index < educationInfoLength; index++) {
          newEducationCertificateFiles.push({ file: null });
        }
        setState((el) => ({
          ...el,
          educationCertificateFiles: newEducationCertificateFiles,
        }));
      });
    fetch(`${URLS.backendJobGrades}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          jobGrades: result.jobGrades,
        }));
      });

    fetch(`${URLS.backendContracts}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          contracts: result.contracts,
        }));
      });

    fetch(`${URLS.backendDepartments}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          departments: result.departments,
        }));
      });

    fetch(`${URLS.backendLocations}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          locations: result.locations,
        }));
      });
    fetch(`${URLS.backendEmployeeHMOs}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          employeeHMOs: result.employeeHMOs,
        }));
      });

    fetch(`${URLS.backendLifeInsurances}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          lifeInsurances: result.lifeInsurances,
        }));
      });
    fetch(`${URLS.backendEmployees}/supervisors`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.supervisors) {
          setState((el) => ({
            ...el,
            supervisors: result.supervisors,
          }));
        }
      });

    fetch(`${URLS.backendProjects}/getProjectsByEmployeeId/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("result.projects", result.projects);
        setState((el) => ({
          ...el,
          projects: result.projects,
        }));
      });
    fetch(`${URLS.backendClients}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          clientList: result.clients,
        }));
      });

    fetch(`${URLS.backendEmployees}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          employeesList: result.employees,
        }));
      });
    fetch(`${URLS.backendTasks}/tasks/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          projectTasks: result.tasks,
        }));
      });

    fetch(`${URLS.backendsalarySetting}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const { typeOfSalary } = result?.jobGrades[0];

        setState((el) => ({
          ...el,
          typeOfSalary,
        }));
      });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      componentWillMount();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const eduRef = useRef();
  const emergencyRef = useRef();
  const resumeRef = useRef();

  const getAddressValue = (apartment, street, city, state, zip) => {
    let result = "";
    if (apartment && apartment !== "") result = result.concat(apartment);
    if (street && street !== "") result = result.concat(" " + street);
    if (city && city !== "") result = result.concat(", " + city);
    if (state && state !== "") result = result.concat(", " + state);
    if (zip && zip !== "") result = result.concat(" " + zip);
    return result;
  };
  const getPhoneValue = (phone1, phone2) => {
    let result = "";
    if (phone1 && phone1 !== "") result = result.concat(phone1);
    if (phone2 && phone2 !== "") {
      if (result !== "") result = result.concat(", " + phone2);
      else result = result.concat(phone2);
    }
    return result;
  };
  const changeShowJobState = () => {
    const { show_add_job } = state;
    setState((el) => ({
      ...el,
      show_add_job: !show_add_job,
    }));
  };
  const changeShowLocationState = () => {
    const { show_add_location } = state;
    setState((el) => ({
      ...el,
      show_add_location: !show_add_location,
    }));
  };
  const changeShowEmployeeHMOState = () => {
    const { show_add_employeeHMO } = state;
    setState((el) => ({
      ...el,
      show_add_employeeHMO: !show_add_employeeHMO,
    }));
  };
  const changeShowLifeInsuranceState = () => {
    const { show_add_lifeInsurance } = state;
    setState((el) => ({
      ...el,
      show_add_lifeInsurance: !show_add_lifeInsurance,
    }));
  };
  const updateJobGradesList = (item) => {
    const { jobGrades } = state;
    jobGrades.push(item);
    setState((el) => ({
      ...el,
      jobGrades,
    }));
  };
  const updateLocationsList = (item) => {
    const { locations } = state;
    locations.push(item);
    setState((el) => ({
      ...el,
      locations,
    }));
  };
  const updateEmployeeHMOsList = (item) => {
    const { employeeHMOs } = state;
    employeeHMOs.push(item);
    setState((el) => ({
      ...el,
      employeeHMOs,
    }));
  };
  const updateLifeInsurancesList = (item) => {
    const { lifeInsurances } = state;
    lifeInsurances.push(item);
    setState((el) => ({
      ...el,
      lifeInsurances,
    }));
  };
  const handleEducationInfoField = (idx) => (evt) => {
    const newEducationInfo = state.educationInfo.map((info, eIdx) => {
      if (idx !== eIdx) return info;
      let tName = evt.target.name;
      const value = evt.target.value;
      return { ...info, [tName]: value };
    });
    setState((el) => ({ ...el, educationInfo: newEducationInfo }));
  };

  const handleEducationInfoCompleteField = (idx) => (evt) => {
    const newEducationInfo = state.educationInfo.map((info, eIdx) => {
      if (idx !== eIdx) return info;
      let tName = "completeDate";
      const value = evt.target
        ? evt.target.value
        : moment(evt).format("DD/MM/YYYY");
      return { ...info, [tName]: value };
    });
    setState((el) => ({ ...el, educationInfo: newEducationInfo }));
  };

  const handleEducationInfoStartField = (idx) => (evt) => {
    const newEducationInfo = state.educationInfo.map((info, eIdx) => {
      if (idx !== eIdx) return info;
      let tName = "startDate";
      const value = evt.target
        ? evt.target.value
        : moment(evt).format("DD/MM/YYYY");
      return { ...info, [tName]: value };
    });
    setState((el) => ({ ...el, educationInfo: newEducationInfo }));
  };
  const handleEducationInfoFileField = (idx) => {
    // const refName = "certificate_" + idx;
    // const file = eduRef[refName].files[0] ? eduRef[refName].files[0] : null;
    const file = eduRef.current?.files[0] ? eduRef.current?.files[0] : null;
    if (file !== null) {
      const { educationInfo, educationCertificateFiles } = state;
      let currentDate = new Date();
      currentDate = currentDate.getTime();
      educationInfo[idx].certificate = currentDate + ".pdf";
      educationCertificateFiles[idx].file = file;
      setState((el) => ({
        ...el,
        educationInfo: educationInfo,
        educationCertificateFiles: educationCertificateFiles,
      }));
    }
  };

  const addMoreEducationInfo = () => {
    const { educationInfo, educationCertificateFiles } = state;
    setState((el) => ({
      ...el,
      educationInfo: educationInfo.concat({
        institution: "",
        subject: "",
        startDate: "",
        completeDate: "",
        degree: "",
        grade: "",
        certificate: "",
      }),
    }));
    setState((el) => ({
      ...el,
      educationCertificateFiles: educationCertificateFiles.concat({
        file: null,
      }),
    }));
  };
  const updatePersonalInfoFirstName = (event) => {
    const { personalInfo } = state;
    personalInfo.name.first = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };

  const updateJobInfoJoinDate = (event) => {
    const { jobInfo } = state;
    jobInfo.joinDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };

  const updatePersonalInfoLastName = (event) => {
    const { personalInfo } = state;
    personalInfo.name.last = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoMiddleName = (event) => {
    const { personalInfo } = state;
    personalInfo.name.middle = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressApartment = (event) => {
    const { personalInfo } = state;
    personalInfo.address.apartment = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressStreet = (event) => {
    const { personalInfo } = state;
    personalInfo.address.street = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressCity = (event) => {
    const { personalInfo } = state;
    personalInfo.address.city = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressState = (event) => {
    const { personalInfo } = state;
    personalInfo.address.state = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressZip = (event) => {
    const { personalInfo } = state;
    personalInfo.address.zip = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoPhoneHome = (event) => {
    const { personalInfo } = state;
    personalInfo.phone.home = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoPhoneAlternate = (event) => {
    const { personalInfo } = state;
    personalInfo.phone.alternate = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoEmail = (event) => {
    const { personalInfo } = state;
    personalInfo.email = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoGovtId = (event) => {
    const { personalInfo } = state;
    personalInfo.govtId = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalGender = (event) => {
    const { personalInfo } = state;
    personalInfo.gender = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoBirth = (event) => {
    try {
      const { personalInfo } = state;
      personalInfo.birth = event.target
        ? event.target.value
        : moment(event).format("DD/MM/YYYY");
      setState((el) => ({
        ...el,
        personalInfo,
      }));
    } catch (e) {
      console.log("there was an error :: ", e);
    }
  };
  const updatePersonalInfoMartial = (event) => {
    const { personalInfo } = state;
    personalInfo.martial = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoSpouseName = (event) => {
    const { personalInfo } = state;
    personalInfo.spouse.name = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoSpouseEmployer = (event) => {
    const { personalInfo } = state;
    personalInfo.spouse.employer = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoSpousePhone = (event) => {
    const { personalInfo } = state;
    personalInfo.spouse.phone = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoPrevEmployeeEmployee = (event) => {
    const { personalInfo } = state;
    personalInfo.prevEmployee.employee = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoPrevEmployeePhone = (event) => {
    const { personalInfo } = state;
    personalInfo.prevEmployee.phone = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updateJobInfoTitle = (event) => {
    const { jobInfo } = state;
    jobInfo.title = event.target.value;
    setState((el) => ({
      jobInfo,
    }));
  };
  const updateJobInfoEmployeeId = (event) => {
    const { jobInfo } = state;
    jobInfo.employeeId = event.target.value;
    setState((el) => ({
      jobInfo,
    }));
  };
  const updateJobInfoGrade = (event) => {
    const { jobInfo, jobGrades } = state;
    const value = event.target.value;
    if (value === "add_job") {
      const { show_add_job } = state;
      setState((el) => ({
        show_add_job: !show_add_job,
      }));
    } else {
      const grade = jobGrades.filter((grades) => grades._id === value);
      const gradeTotal = Object.entries(grade[0].salary).reduce(
        (c, p) => c + p[1],
        0
      );
      const totalCustom =
        grade[0]?.custom?.length > 0
          ? grade[0]?.custom?.reduce(
              (c, p) => parseFloat(c) + parseFloat(p?.value),
              0
            )
          : 0;

      jobInfo.salary = gradeTotal + totalCustom;
      jobInfo.grade = value;
      setState((el) => ({
        jobInfo,
      }));
    }
  };
  const updateJobInfoSupervisor = (params) => {
    const { jobInfo } = state;
    jobInfo.supervisor = params.map((data) => {
      return data.id;
    });
    setState((el) => ({
      ...el,
      jobInfo,
    }));
    console.log(state.jobInfo);
  };
  const updateJobInfoDepartment = (event) => {
    const { jobInfo } = state;
    jobInfo.department = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoWorkLocation = (event) => {
    const { jobInfo, locations } = state;
    const value = event.target.value;
    if (value === "add_location") {
      const { show_add_location } = state;
      setState((el) => ({
        ...el,
        show_add_location: !show_add_location,
      }));
    } else {
      let branchesLocations = locations.find((el) => el._id === value);
      branchesLocations = branchesLocations?.branches;
      jobInfo.workLocation = value;
      jobInfo.workLocationBranch = "";
      setState((el) => ({
        ...el,
        jobInfo,
        branchesLocations,
      }));
    }
  };
  const updateJobInfoEmail = (event) => {
    const { jobInfo } = state;
    jobInfo.email = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoWorkPhone = (event) => {
    const { jobInfo } = state;
    jobInfo.workPhone = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoCellPhone = (event) => {
    const { jobInfo } = state;
    jobInfo.cellPhone = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoStartDate = (event) => {
    const { jobInfo } = state;
    jobInfo.startDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoSalary = (event) => {
    const { jobInfo } = state;
    jobInfo.salary = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoStatus = (event) => {
    const { jobInfo } = state;
    jobInfo.status = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoSalaryBasis = (event) => {
    const { jobInfo } = state;
    jobInfo.salaryBasis = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoContract = (event) => {
    const { jobInfo } = state;
    jobInfo.contract = event.target.checked;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoContractType = (event) => {
    const { jobInfo } = state;
    jobInfo.contractType = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateEmergencyInfoNameFirst = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.name.first = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoNameLast = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.name.last = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoNameMiddle = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.name.middle = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressApartment = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.apartment = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressStreet = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.street = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressCity = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.city = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressState = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.state = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressZip = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.zip = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoPhonePrimary = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.phone.primary = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoPhoneSecondary = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.phone.secondary = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRelation = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.relation = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoGuarantorTitle = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.guarantor.title = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoGuarantorName = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.guarantor.name = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoGuarantorPhone = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.guarantor.phone = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoGuarantorAddress = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.guarantor.address = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRefereeTitle = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.referee.title = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRefereeName = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.referee.name = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRefereePhone = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.referee.phone = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRefereeAddress = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.referee.address = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoEmployeeHMO = (event) => {
    const { emergencyInfo } = state;
    const value = event.target.value;
    if (value === "add_employeeHMO") {
      const { show_add_employeeHMO } = state;
      setState((el) => ({
        ...el,
        show_add_employeeHMO: !show_add_employeeHMO,
      }));
    } else {
      emergencyInfo.employeeHMO = value;
      setState((el) => ({
        ...el,
        emergencyInfo,
      }));
    }
  };
  const updateEmergencyInfoLifeInsurance = (event) => {
    const { emergencyInfo } = state;
    const value = event.target.value;
    if (value === "add_lifeInsurance") {
      const { show_add_lifeInsurance } = state;
      setState((el) => ({
        ...el,
        show_add_lifeInsurance: !show_add_lifeInsurance,
      }));
    } else {
      emergencyInfo.lifeInsurance = value;
      setState((el) => ({
        ...el,
        emergencyInfo,
      }));
    }
  };
  const updateEmergencyInfoImage = (event) => {
    const { emergencyInfo } = state;
    let currentDate = new Date();
    currentDate = currentDate.getTime();
    emergencyInfo.image = currentDate + ".png";
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
    let file = emergencyRef.current?.emergency_image.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      setState((el) => ({
        ...el,
        displayImageSrc: [reader.result],
      }));
    };
  };
  const updateEmergencyInfoResume = (event) => {
    const { emergencyInfo } = state;
    let currentDate = new Date();
    currentDate = currentDate.getTime();
    emergencyInfo.resume = currentDate + ".pdf";
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
    setState((el) => ({
      ...el,
      displayResumeSrc: event.target.files[0],
    }));
  };
  const onDocumentLoadSuccess = (e) => {
    // console.log("success", e);
  };

  const updateJobInfoWorkLocationBranch = (event) => {
    const { jobInfo, branchesLocations } = state;
    const value = event.target.value;
    jobInfo.workLocationBranch = value;
    let theState = branchesLocations.find((el) => el.name === value);

    theState = state?.state;
    jobInfo.state = theState;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };

  const savePersonalInfo = (e) => {
    e.preventDefault();
    const { _id, personalInfo, jobInfo, educationInfo, emergencyInfo } = state;

    let formData = new FormData();
    const employee = {
      _id,
      personalInfo,
      jobInfo,
      emergencyInfo,
      educationInfo,
    };
    formData.append("employee", JSON.stringify(employee));
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        toast.success("Updated Successful", {
          position: "top-right",
        });
        // console.log(result);
        // props.updateEmployeesList(result.employee);
      });
  };
  const saveJobInfo = (e) => {
    e.preventDefault();
    const { _id, personalInfo, jobInfo, educationInfo, emergencyInfo } = state;
    let formData = new FormData();
    const employee = {
      _id,
      personalInfo,
      jobInfo,
      emergencyInfo,
      educationInfo,
    };
    formData.append("employee", JSON.stringify(employee));
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        toast.success("Updated Successful", {
          position: "top-right",
        });
        // props.updateEmployeesList(result.employee);
      });
  };

  const saveEmergencyInfo = (e) => {
    e.preventDefault();
    const { _id, personalInfo, jobInfo, educationInfo, emergencyInfo } = state;
    let formData = new FormData();
    const employee = {
      _id,
      personalInfo,
      jobInfo,
      emergencyInfo,
      educationInfo,
    };
    formData.append("employee", JSON.stringify(employee));
    if (
      emergencyRef.current.emergency_image.files &&
      emergencyRef.current.emergency_image.files[0]
    )
      formData.append(
        "file",
        emergencyRef.current.emergency_image.files[0],
        emergencyInfo.image
      );
    if (
      resumeRef.current.emergency_resume.files &&
      resumeRef.current.emergency_resume.files[0]
    )
      formData.append(
        "file",
        resumeRef.current.emergency_resume.files[0],
        emergencyInfo.resume
      );
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        toast.success("Updated Successful", {
          position: "top-right",
        });
        setState((el) => ({
          ...el,
          emergencyInfo: result.employee.emergencyInfo,
        }));
        // props.updateEmployeesList(result.employee);
      });
  };
  const saveEducationInfo = (e) => {
    e.preventDefault();
    const {
      _id,
      personalInfo,
      jobInfo,
      educationInfo,
      emergencyInfo,
      educationCertificateFiles,
    } = state;
    let formData = new FormData();
    const employee = {
      _id,
      personalInfo,
      jobInfo,
      emergencyInfo,
      educationInfo,
    };
    formData.append("employee", JSON.stringify(employee));
    for (let i = 0; i < educationInfo.length; i++) {
      if (
        educationCertificateFiles[i].file &&
        educationCertificateFiles[i].file !== ""
      )
        formData.append(
          "file",
          educationCertificateFiles[i].file,
          educationInfo[i].certificate
        );
    }
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        toast.success("Updated Successful", {
          position: "top-right",
        });
        // props.updateEmployeesList(result.employee);
      });
  };
  const editTheProject = (_id) => {
    setState((el) => ({ ...el, editProject: _id }));
  };

  // const deleteProject = (_id) => {
  //   setState((el) => ({ ...el, deleteProject: _id }));
  // };

  const getEditProjectDataFromId = (id) => {
    const { projects } = state;
    return projects.find((item) => id === item._id);
  };

  const handleDeleteProject = () => {
    let projects = state.projects;
    const projectId = state.deleteProject;
    const token = "dummy token";

    fetch(`${URLS.backendProjects}/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ projectId }),
    })
      .then((res) => res.json())
      .then((result) => {
        projects = projects.filter((project) => project._id !== projectId);
        setState((el) => ({ ...el, projects }));
      });

    window.$("#delete_project").modal("toggle");
  };

  const updateProjectsList = () => {};

  const {
    personalInfo,
    jobInfo,
    emergencyInfo,
    educationInfo,
    jobGrades,
    contracts,
    departments,
    locations,
    educationCertificateFiles,
    displayImageSrc,
    displayResumeSrc,
    pdfPageNumber,
    employeeHMOs,
    lifeInsurances,
    supervisors,
    projects,
    editProject,
    employeesList,
    clientList,
    projectTasks,
    // collaborationUrl,
    branchesLocations,
  } = state;
  const editProjectData = getEditProjectDataFromId(editProject);
  // const profileImage =
  //   emergencyInfo && emergencyInfo.image
  //     ? URLS.backendStatic + "/" + emergencyInfo.image
  //     : "/assets/img/profiles/avatar-02.jpg";
  // const supervisorProfileImage =
  //   supervisors.length && jobInfo
  //     ? supervisors.map((item) => {
  //         return item.id === jobInfo.supervisor
  //           ? URLS.backendStatic + "/" + item?.image
  //           : "/assets/img/profiles/avatar-02.jpg";
  //       })
  //     : "/assets/img/profiles/avatar-02.jpg";
  const displayImage = displayImageSrc
    ? displayImageSrc
    : URLS.backendStatic + "/" + emergencyInfo?.image;
  const displayResume = displayResumeSrc
    ? displayResumeSrc
    : URLS.backendStatic + "/" + emergencyInfo?.resume;
  let displayEducationCertificates = [];
  for (let i = 0; i < educationInfo?.length; i++) {
    let sFileUrl = URLS.backendStatic + "/" + educationInfo[i]?.certificate;
    let fff =
      educationCertificateFiles[i] && educationCertificateFiles[i]?.file;
    if (fff) {
      displayEducationCertificates.push(fff);
    } else {
      displayEducationCertificates.push(sFileUrl);
    }
  }
  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow" />
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={`${URLS.employees}`}>Employees</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>My Profile</BreadcrumbItem>
                </Breadcrumb>
                {/* <h4 className='page-title'>My Profile</h4> */}
              </div>
            </div>
            {/*<!-- /Page Title -->*/}

            <div className="card-box mb-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <img
                          alt=""
                          src={
                            emergencyInfo && emergencyInfo.image
                              ? URLS.backendStatic + "/" + emergencyInfo.image
                              : "/assets/img/profiles/avatar-02.jpg"
                          }
                        />
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {(personalInfo?.name?.first
                                ? personalInfo?.name?.first
                                : "") +
                                " " +
                                (personalInfo?.name?.middle
                                  ? personalInfo?.name?.middle
                                  : "") +
                                " " +
                                (personalInfo?.name?.last
                                  ? personalInfo?.name?.last
                                  : "")}
                            </h3>
                            <h6 className="text-muted">
                              {departments.length &&
                                departments.map((item) => {
                                  return item._id === jobInfo.department
                                    ? item.name
                                    : "";
                                })}
                            </h6>
                            <small className="text-muted">
                              {jobInfo.title}
                            </small>
                            <div className="staff-id">{jobInfo.employeeId}</div>
                            <div className="small doj text-muted">
                              {jobInfo.startDate}
                            </div>
                            {/* <div className="staff-msg">
                                <a
                                  className="btn btn-custom"
                                  href={collaborationUrl + "/messenger"}
                                >
                                  Send Message
                                </a>
                              </div> */}
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <div
                              style={{ display: "flex", padding: "4px 0px" }}
                            >
                              <div className="title" style={{ flex: 1 }}>
                                Phone:{" "}
                              </div>
                              <div className="text" style={{ flex: 1 }}>
                                <span className="p-cursor">
                                  {" "}
                                  {personalInfo?.phone?.home}{" "}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{ display: "flex", padding: "4px 0px" }}
                            >
                              <div className="title" style={{ flex: 1 }}>
                                Email:{" "}
                              </div>
                              <div className="text" style={{ flex: 1 }}>
                                <span className="p-cursor">
                                  {" "}
                                  {personalInfo?.email}{" "}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{ display: "flex", padding: "4px 0px" }}
                            >
                              <div className="title" style={{ flex: 1 }}>
                                Birthday:{" "}
                              </div>
                              <div className="text" style={{ flex: 1 }}>
                                {" "}
                                {personalInfo?.birth}{" "}
                              </div>
                            </div>
                            <div
                              style={{ display: "flex", padding: "4px 0px" }}
                            >
                              <div className="title" style={{ flex: 1 }}>
                                Address:{" "}
                              </div>
                              <div className="text" style={{ flex: 1 }}>
                                {" "}
                                {getAddressValue(
                                  personalInfo?.address?.apartment,
                                  personalInfo?.address?.street,
                                  personalInfo?.address?.city,
                                  personalInfo?.address?.state,
                                  personalInfo?.address?.zip
                                )}{" "}
                              </div>
                            </div>
                            {/* <div style={{ display: 'flex', padding: '4px 0px' }}>
																<div className='title' style={{ flex: 1 }}>Reports to: </div>
																<div className='text' style={{ flex: 1 }}>
																	<div className='avatar-box'>
																		<div className='avatar avatar-xs'>
																			<img src={supervisorProfileImage} alt='' />
																		</div>
																	</div>
																	<a href={`${jobInfo.supervisor}`}>{supervisors.length && supervisors.map((item) => { return item.id === jobInfo.supervisor ? item.name : '' })}</a>
																</div>
															</div> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className='pro-edit'><a data-target='#profile_info' data-toggle='modal' className='edit-icon' href='Javascript:void(0);'><i className='fa fa-pencil'></i></a></div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="card-box tab-box">
              <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                  <ul className="nav nav-tabs nav-tabs-bottom">
                    <li className="nav-item">
                      <a
                        href="#emp_profile"
                        data-toggle="tab"
                        className="nav-link active"
                      >
                        Profile
                      </a>
                    </li>
                    {/* <li className="nav-item">
                        <a
                          href="#emp_projects"
                          data-toggle="tab"
                          className="nav-link"
                        >
                          Projects
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#tasks" data-toggle="tab" className="nav-link">
                          Tasks
                        </a>
                      </li> */}
                    {/* <li className='nav-item'><a href='#bank_statutory' data-toggle='tab' className='nav-link'>Bank & Statutory <small className='text-danger'>(Admin Only)</small></a></li> */}
                    <li className="nav-item">
                      <a
                        href="#emp_skills"
                        data-toggle="tab"
                        className="nav-link"
                      >
                        Skills/Certification
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#emp_trainings"
                        data-toggle="tab"
                        className="nav-link"
                      >
                        Training
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#emp_documents"
                        data-toggle="tab"
                        className="nav-link"
                      >
                        Documents
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="tab-content">
              {/*<!-- Profile Info Tab -->*/}
              <div
                id="emp_profile"
                className="pro-overview tab-pane fade show active"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="card-box profile-box">
                      <h3 className="card-title">
                        Personal Information{" "}
                        <span
                          className="edit-icon p-cursor"
                          data-toggle="modal"
                          data-target="#personal_info_modal"
                        >
                          <i className="fa fa-pencil"></i>
                        </span>
                      </h3>
                      <ul className="personal-info">
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Name:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {(personalInfo?.name?.first
                              ? personalInfo?.name?.first
                              : "") +
                              " " +
                              (personalInfo?.name?.middle
                                ? personalInfo?.name?.middle
                                : "") +
                              " " +
                              (personalInfo?.name?.last
                                ? personalInfo?.name?.last
                                : "")}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Address:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {getAddressValue(
                              personalInfo?.address?.apartment,
                              personalInfo?.address?.street,
                              personalInfo?.address?.city,
                              personalInfo?.address?.state,
                              personalInfo?.address?.zip
                            )}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Tel:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            <span className="p-cursor">
                              {getPhoneValue(
                                personalInfo?.phone?.home,
                                personalInfo?.phone?.alternate
                              )}
                            </span>
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Email:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            <span className="p-cursor">
                              {personalInfo?.email}
                            </span>
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Govt ID:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {personalInfo?.govtId}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Birthday:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {personalInfo?.birth}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Marital Status:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {personalInfo?.martial}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Employment of spouse:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {personalInfo?.spouse?.name}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Spouse’s Employer:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {personalInfo?.spouse?.employer}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Spouse’s Work Phone:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {personalInfo?.spouse?.phone}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Previous Employee:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {personalInfo?.prevEmployee?.employee}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Previous Employer Phone:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {personalInfo?.prevEmployee?.phone}
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-box profile-box">
                      <h3 className="card-title">
                        Emergency Contact{" "}
                        <span
                          className="p-cursor edit-icon"
                          data-toggle="modal"
                          data-target="#emergency_contact_modal"
                        >
                          <i className="fa fa-pencil"></i>
                        </span>
                      </h3>
                      <ul className="personal-info">
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Name:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {(emergencyInfo?.name?.first
                              ? emergencyInfo.name.first
                              : "") +
                              " " +
                              (emergencyInfo?.name?.middle
                                ? emergencyInfo?.name?.middle
                                : "") +
                              " " +
                              (emergencyInfo?.name?.last
                                ? emergencyInfo?.name?.last
                                : "")}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Address:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {getAddressValue(
                              emergencyInfo?.address?.apartment,
                              emergencyInfo?.address?.street,
                              emergencyInfo?.address?.city,
                              emergencyInfo?.address?.state,
                              emergencyInfo?.address?.zip
                            )}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Phone:{" "}
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {getPhoneValue(
                              emergencyInfo?.phone?.primary,
                              emergencyInfo?.phone?.secondary
                            )}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Relationship:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {emergencyInfo?.relation}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Guarantor:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {(emergencyInfo?.guarantor?.title
                              ? emergencyInfo?.guarantor?.title
                              : "") +
                              " " +
                              (emergencyInfo?.guarantor?.name
                                ? emergencyInfo?.guarantor?.name
                                : "")}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Guarantor Phone:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {emergencyInfo?.guarantor?.phone}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Guarantor Address:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {emergencyInfo?.guarantor?.address}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Referee:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {(emergencyInfo?.referee?.title
                              ? emergencyInfo?.referee?.title
                              : "") +
                              " " +
                              (emergencyInfo?.referee?.name
                                ? emergencyInfo?.referee?.name
                                : "")}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Referee Phone:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {emergencyInfo?.referee?.phone}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Referee Address:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {emergencyInfo?.referee?.address}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Employee HMO:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {employeeHMOs.length &&
                              employeeHMOs.map((item) => {
                                return item?._id === emergencyInfo?.employeeHMO
                                  ? item?.name
                                  : "";
                              })}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Group Life Insurance:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {lifeInsurances.length &&
                              lifeInsurances.map((item) => {
                                return item?._id ===
                                  emergencyInfo?.lifeInsurance
                                  ? item?.name
                                  : "";
                              })}
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card-box profile-box">
                      <h3 className="card-title">
                        Job Information{" "}
                        <span
                          className="edit-icon p-cursor"
                          data-toggle="modal"
                          data-target="#job_information_modal"
                        >
                          <i className="fa fa-pencil"></i>
                        </span>
                      </h3>
                      <ul className="personal-info">
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Job Title:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {jobInfo.title}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Employee ID:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {jobInfo?.employeeId}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Job Grade:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {jobGrades?.length &&
                              jobGrades?.map((item) => {
                                return item?._id === jobInfo?.grade
                                  ? item?.name
                                  : "";
                              })}
                          </div>
                        </div>

                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Contract:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {contracts?.length &&
                              contracts?.map((item) => {
                                return item?._id === jobInfo?.contractType
                                  ? item?.contractName
                                  : "";
                              })}
                          </div>
                        </div>

                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Supervisor:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {supervisors?.length &&
                              jobInfo?.supervisor?.length &&
                              supervisors?.map((item) => {
                                if (jobInfo?.supervisor.indexOf(item?.id) > -1)
                                  return (
                                    <span>
                                      <a
                                        href={`${item.id}`}
                                        style={{
                                          textDecoration: "underline",
                                        }}
                                      >
                                        {item?.name}
                                      </a>
                                      &nbsp;&nbsp;
                                    </span>
                                  );
                                else return <span></span>;
                              })}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Department:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {departments.length &&
                              departments.map((item) => {
                                return item._id === jobInfo.department
                                  ? item.name
                                  : "";
                              })}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Work Location:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {locations.length &&
                              locations.map((item) => {
                                return item._id === jobInfo.workLocation
                                  ? item.name
                                  : "";
                              })}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Email:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {jobInfo.email}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Work Phone:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {jobInfo.workPhone}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Cell Phone:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {jobInfo.cellPhone}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Join Date:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {jobInfo?.joinDate
                              ? moment(jobInfo?.joinDate, "DD/MM/YYYY").format(
                                  "DD MM YYYY"
                                )
                              : ""}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Start Date:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {jobInfo?.startDate
                              ? moment(jobInfo?.startDate, "DD/MM/YYYY").format(
                                  "DD MMM YYYY"
                                )
                              : ""}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Salary:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {(jobInfo.salary
                              ? currency(jobInfo.salary, {
                                  symbol: "",
                                }).format()
                              : "") +
                              " " +
                              (jobInfo.salaryBasis ? jobInfo.salaryBasis : "")}
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "4px 0px" }}>
                          <div className="title" style={{ flex: 1 }}>
                            Status:
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {jobInfo.status}
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-box profile-box">
                      <h3 className="card-title">
                        Education Information{" "}
                        <span
                          className="edit-icon p-cursor"
                          data-toggle="modal"
                          data-target="#education_info"
                        >
                          <i className="fa fa-pencil"></i>
                        </span>
                      </h3>
                      <div className="experience-box">
                        <ul className="experience-list">
                          {educationInfo?.map((education) => {
                            return (
                              <li>
                                <div className="experience-user">
                                  <div className="before-circle"></div>
                                </div>
                                <div className="experience-content">
                                  <div className="timeline-content">
                                    <a href="#/" className="name">
                                      {education.institution}
                                    </a>
                                    <div>{education.subject}</div>
                                    <div>Grade: {education.grade}</div>
                                    <span className="time">
                                      {education.startDate +
                                        " - " +
                                        education.completeDate}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<!-- /Profile Info Tab -->*/}

              {/*<!-- Projects Tab -->*/}
              <div className="tab-pane fade" id="emp_projects">
                <div className="row staff-grid-row">
                  {projects.length
                    ? projects.map((project) => {
                        let projectTeam = [];
                        let projectLeader = [];
                        if (project.team && project.team.length) {
                          projectTeam = project.team.map((employee) => {
                            let name = employee.personalInfo.name.first;
                            name += employee.personalInfo.name.middle
                              ? " " + employee.personalInfo.name.middle
                              : "";
                            name += employee.personalInfo.name.last
                              ? " " + employee.personalInfo.name.last
                              : "";
                            const id = employee._id;
                            const image = employee.emergencyInfo.image;
                            return { name: name, id: id, image: image };
                          });
                        }
                        if (
                          project.projectLeader &&
                          project.projectLeader.length
                        ) {
                          projectLeader = project.projectLeader.map(
                            (employee) => {
                              let name = employee.personalInfo.name.first;
                              name += employee.personalInfo.name.middle
                                ? " " + employee.personalInfo.name.middle
                                : "";
                              name += employee.personalInfo.name.last
                                ? " " + employee.personalInfo.name.last
                                : "";
                              const id = employee._id;
                              const image = employee.emergencyInfo.image;
                              return { name: name, id: id, image: image };
                            }
                          );
                        }
                        return (
                          <Project
                            key={project._id}
                            project={project}
                            projectTeamList={projectTeam}
                            projectLeaderList={projectLeader}
                            onEdit={editTheProject}
                            canShowControls={false}
                          />
                        );
                      })
                    : ""}
                </div>
              </div>
              <EditProjectModal
                mode="edit"
                project={editProjectData}
                updateProjectsList={updateProjectsList}
                clientList={clientList}
                employeesList={employeesList}
              />
              {/*<!-- /Projects Tab -->*/}

              {/*<!-- Delete Project Modal -->*/}
              <div
                className="modal custom-modal fade"
                id="delete_project"
                role="dialog"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="form-header">
                        <h3>Delete Project</h3>
                        <p>Are you sure want to delete?</p>
                      </div>
                      <div className="modal-btn delete-action">
                        <div className="row">
                          <div className="col-6">
                            <span
                              className="btn btn-primary continue-btn p-cursor"
                              onClick={() => handleDeleteProject()}
                            >
                              Delete
                            </span>
                          </div>
                          <div className="col-6">
                            <span
                              data-dismiss="modal"
                              className="btn btn-primary cancel-btn p-cursor"
                            >
                              Cancel
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<!-- /Delete Project Modal -->*/}

              {/*<!-- Bank Statutory Tab -->*/}
              <div className="tab-pane fade" id="bank_statutory">
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title"> Basic Salary Information</h3>
                    <form>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Salary basis{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select className="select">
                              <option>Select salary basis type</option>
                              <option>Hourly</option>
                              <option>Daily</option>
                              <option>Weekly</option>
                              <option>Monthly</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Salary amount{" "}
                            </label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type your salary amount"
                                value="0.00"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Payment type
                            </label>
                            <select className="select">
                              <option>Select payment type</option>
                              <option>Bank transfer</option>
                              <option>Check</option>
                              <option>Cash</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h3 className="card-title"> PF Information</h3>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              PF contribution
                            </label>
                            <select className="select">
                              <option>Select PF contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              PF No. <span className="text-danger">*</span>
                            </label>
                            <select className="select">
                              <option>Select PF contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Employee PF rate
                            </label>
                            <select className="select">
                              <option>Select PF contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Additional rate{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select className="select">
                              <option>Select additional rate</option>
                              <option>0%</option>
                              <option>1%</option>
                              <option>2%</option>
                              <option>3%</option>
                              <option>4%</option>
                              <option>5%</option>
                              <option>6%</option>
                              <option>7%</option>
                              <option>8%</option>
                              <option>9%</option>
                              <option>10%</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">Total rate</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="N/A"
                              value="11%"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Employee PF rate
                            </label>
                            <select className="select">
                              <option>Select PF contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Additional rate{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select className="select">
                              <option>Select additional rate</option>
                              <option>0%</option>
                              <option>1%</option>
                              <option>2%</option>
                              <option>3%</option>
                              <option>4%</option>
                              <option>5%</option>
                              <option>6%</option>
                              <option>7%</option>
                              <option>8%</option>
                              <option>9%</option>
                              <option>10%</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">Total rate</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="N/A"
                              value="11%"
                            />
                          </div>
                        </div>
                      </div>

                      <hr />
                      <h3 className="card-title"> ESI Information</h3>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              ESI contribution
                            </label>
                            <select className="select">
                              <option>Select ESI contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              ESI No. <span className="text-danger">*</span>
                            </label>
                            <select className="select">
                              <option>Select ESI contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Employee ESI rate
                            </label>
                            <select className="select">
                              <option>Select ESI contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Additional rate{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select className="select">
                              <option>Select additional rate</option>
                              <option>0%</option>
                              <option>1%</option>
                              <option>2%</option>
                              <option>3%</option>
                              <option>4%</option>
                              <option>5%</option>
                              <option>6%</option>
                              <option>7%</option>
                              <option>8%</option>
                              <option>9%</option>
                              <option>10%</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">Total rate</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="N/A"
                              value="11%"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="submit-section">
                        <button
                          className="btn btn-primary submit-btn"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*<!-- /Bank Statutory Tab -->*/}
              <div className="tab-pane fade" id="emp_skills">
                <EmployeeSkills eId={state._id} />
              </div>
              <div className="tab-pane fade" id="emp_trainings">
                <EmployeeTraining eId={state._id} />
              </div>
              <div className="tab-pane fade" id="emp_documents">
                <DocumentModal />
              </div>
              <div id="tasks" className="tab-pane fade">
                <div className="project-task">
                  <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        href="#all_tasks"
                        data-toggle="tab"
                        aria-expanded="true"
                      >
                        All Tasks
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#pending_tasks"
                        data-toggle="tab"
                        aria-expanded="false"
                      >
                        Pending Tasks
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#under_reviewed_tasks"
                        data-toggle="tab"
                        aria-expanded="false"
                      >
                        Under Reviewing
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#completed_tasks"
                        data-toggle="tab"
                        aria-expanded="false"
                      >
                        Completed Tasks
                      </a>
                    </li>
                  </ul>
                  <AllTypeTasksEmployee projectTasks={projectTasks} />
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Page Content -->*/}

          {/*<!-- Profile Modal -->*/}
          <div
            id="profile_info"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Profile Information</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="profile-img-wrap edit-img">
                          <img
                            className="inline-block"
                            src="assets/img/profiles/avatar-02.jpg"
                            alt="user"
                          />
                          <div className="fileupload btn">
                            <span className="btn-text">edit</span>
                            <input className="upload" type="file" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value="John"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value="Doe"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Birth Date</label>
                              <div className="cal-icon">
                                <input
                                  className="form-control datetimepicker"
                                  type="text"
                                  value="05/06/1985"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Gender</label>
                              <select className="select form-control">
                                <option value="male selected">Male</option>
                                <option value="female">Female</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Address</label>
                          <input
                            type="text"
                            className="form-control"
                            value="4487 Snowbird Lane"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>State</label>
                          <input
                            type="text"
                            className="form-control"
                            value="New York"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Country</label>
                          <input
                            type="text"
                            className="form-control"
                            value="United States"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Pin Code</label>
                          <input
                            type="text"
                            className="form-control"
                            value="10523"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input
                            type="text"
                            className="form-control"
                            value="631-889-3206"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Department <span className="text-danger">*</span>
                          </label>
                          <select className="select">
                            <option>Select Department</option>
                            <option>Web Development</option>
                            <option>IT Management</option>
                            <option>Marketing</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Designation <span className="text-danger">*</span>
                          </label>
                          <select className="select">
                            <option>Select Designation</option>
                            <option>Web Designer</option>
                            <option>Web Developer</option>
                            <option>Android Developer</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Reports To <span className="text-danger">*</span>
                          </label>
                          <select className="select">
                            <option>-</option>
                            <option>Wilmer Deluna</option>
                            <option>Lesley Grauer</option>
                            <option>Jeffery Lalor</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="submit-section">
                      <button className="btn btn-primary submit-btn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Profile Modal -->*/}

          {/*<!-- Personal Info Modal -->*/}
          <div
            id="personal_info_modal"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Personal Information</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form onSubmit={savePersonalInfo}>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Full Name
                      </Form.Label>
                      <Col sm={10}>
                        <Row>
                          <Col sm={4}>
                            <Form.Control
                              placeholder="First name"
                              value={personalInfo?.name?.first}
                              onChange={updatePersonalInfoFirstName}
                            />
                          </Col>
                          <Col sm={4}>
                            <Form.Control
                              placeholder="Last name"
                              value={personalInfo?.name?.last}
                              onChange={updatePersonalInfoLastName}
                            />
                          </Col>
                          <Col sm={4}>
                            <Form.Control
                              placeholder="Middle Initial"
                              value={personalInfo?.name?.middle}
                              onChange={updatePersonalInfoMiddleName}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Address
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Row>
                          <Form.Group as={Col} sm={4}>
                            <Form.Control
                              placeholder="Apartment/Unit #"
                              value={personalInfo?.address?.apartment}
                              onChange={updatePersonalInfoAddressApartment}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={8}>
                            <Form.Control
                              placeholder="Street Address"
                              value={personalInfo?.address?.street}
                              onChange={updatePersonalInfoAddressStreet}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} sm={4}>
                            <Form.Control
                              placeholder="City"
                              value={personalInfo?.address?.city}
                              onChange={updatePersonalInfoAddressCity}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={4}>
                            <Form.Control
                              placeholder="State"
                              value={personalInfo?.address?.state}
                              onChange={updatePersonalInfoAddressState}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={4}>
                            <Form.Control
                              placeholder="Zip code"
                              value={personalInfo?.address?.zip}
                              onChange={updatePersonalInfoAddressZip}
                            />
                          </Form.Group>
                        </Form.Row>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Home Phone
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={personalInfo?.phone?.home}
                          onChange={updatePersonalInfoPhoneHome}
                        />
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Alternate Phone
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={personalInfo?.phone?.alternate}
                          onChange={updatePersonalInfoPhoneAlternate}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Email
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          placeholder="Email"
                          value={personalInfo?.email}
                          onChange={updatePersonalInfoEmail}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        SSN or Gov’t ID:
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          placeholder="Gov’t ID:"
                          value={personalInfo?.govtId}
                          onChange={updatePersonalInfoGovtId}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Gender:
                      </Form.Label>
                      <Col sm={6}>
                        <Form.Control
                          as="select"
                          value={personalInfo?.gender}
                          onChange={updatePersonalGender}
                        >
                          <option>Choose...</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Others">Others</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Birth Date
                      </Form.Label>
                      <Col sm={4}>
                        <div classname="datePicker">
                          <i
                            class="fa fa-calendar fa-xs calendar-icon"
                            aria-hidden="true"
                          ></i>
                          <Datepicker
                            value={personalInfo?.birth}
                            closeOnSelect={true}
                            onChange={updatePersonalInfoBirth}
                          />
                        </div>
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Marital Status
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          value={personalInfo?.martial}
                          onChange={updatePersonalInfoMartial}
                        >
                          <option>Choose...</option>
                          <option value="Single">Single</option>
                          <option value="Married">Married</option>
                          <option value="Divorce">Divorce</option>
                          <option value="Widow">Widow</option>
                          <option value="Widower">Widower</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Spouse’s Name
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          value={personalInfo?.spouse?.name}
                          onChange={updatePersonalInfoSpouseName}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Spouse’s Employer
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          placeholder="Spouse’s Employer"
                          value={personalInfo?.spouse?.employer}
                          onChange={updatePersonalInfoSpouseEmployer}
                        />
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Spouse’s Work Phone
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          placeholder="Spouse’s Work Phone"
                          value={personalInfo?.spouse?.phone}
                          onChange={updatePersonalInfoSpousePhone}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Previous Employee
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          placeholder="Previous Employee"
                          value={personalInfo?.prevEmployee?.employee}
                          onChange={updatePersonalInfoPrevEmployeeEmployee}
                        />
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Previous Employer Phone
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          placeholder="Previous Employer Phone"
                          value={personalInfo?.prevEmployee?.phone}
                          onChange={updatePersonalInfoPrevEmployeePhone}
                        />
                      </Col>
                    </Form.Group>
                    <div className="submit-section">
                      <button
                        className="btn btn-primary submit-btn"
                        data-toggle="modal"
                        data-target="#personal_info_modal"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div
            id="job_information_modal"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Job Information</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form onSubmit={saveJobInfo}>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Job Title
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={jobInfo.title}
                          onChange={updateJobInfoTitle}
                        />
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Employee ID
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={jobInfo.employeeId}
                          onChange={updateJobInfoEmployeeId}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Job Grade
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          value={jobInfo.grade}
                          onChange={updateJobInfoGrade}
                        >
                          <option>Select</option>
                          <option value="add_job">Add New</option>
                          <option disabled>__________________</option>
                          {jobGrades.map((jobGrade) => {
                            return (
                              <option value={jobGrade._id}>
                                {jobGrade.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Supervisor
                      </Form.Label>
                      <Col sm={4}>
                        {supervisors.length && (
                          <Multiselect
                            options={supervisors}
                            onSelectOptions={updateJobInfoSupervisor}
                            selectedOptions={jobInfo.supervisor}
                          />
                        )}
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Department
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          value={jobInfo.department}
                          onChange={updateJobInfoDepartment}
                        >
                          <option>Select</option>
                          {departments.map((dep) => {
                            return <option value={dep._id}>{dep.name}</option>;
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Work Location
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          value={jobInfo.workLocation}
                          onChange={updateJobInfoWorkLocation}
                        >
                          <option>Select</option>
                          <option value="add_location">Add New</option>
                          <option disabled>__________________</option>
                          {locations.map((location) => {
                            return (
                              <option value={location._id}>
                                {location.location}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>

                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Email
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={jobInfo.email}
                          onChange={updateJobInfoEmail}
                        />
                      </Col>
                    </Form.Group>

                    {branchesLocations?.length > 0 && (
                      <Form.Group as={Row}>
                        <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                          Branch
                        </Form.Label>
                        <Col sm={4}>
                          <Form.Control
                            as="select"
                            value={jobInfo.workLocationBranch}
                            onChange={updateJobInfoWorkLocationBranch}
                          >
                            <option value="">Select</option>
                            {branchesLocations.map((location) => {
                              return (
                                <option value={location.name}>
                                  {location.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                        <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                          State
                        </Form.Label>
                        <Col sm={4}>
                          <Form.Control value={jobInfo.state} type="text" />
                        </Col>
                      </Form.Group>
                    )}

                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Work Phone
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={jobInfo.workPhone}
                          onChange={updateJobInfoWorkPhone}
                        />
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Cell Phone
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={jobInfo.cellPhone}
                          onChange={updateJobInfoCellPhone}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Join Date <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Col sm={4}>
                        <div classname="datePicker">
                          <i
                            class="fa fa-calendar fa-xs calendar-icon"
                            aria-hidden="true"
                          ></i>
                          <Datepicker
                            value={jobInfo.joinDate}
                            closeOnSelect={true}
                            onChange={updateJobInfoJoinDate}
                          />
                        </div>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Start Date
                      </Form.Label>
                      <Col sm={4}>
                        <div classname="datePicker">
                          <i
                            class="fa fa-calendar fa-xs calendar-icon"
                            aria-hidden="true"
                          ></i>
                          <Datepicker
                            value={jobInfo.startDate}
                            closeOnSelect={true}
                            onChange={updateJobInfoStartDate}
                          />
                        </div>
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Salary
                      </Form.Label>
                      <Col sm={4}>
                        <CurrencyInput
                          className="form-control"
                          value={jobInfo.salary}
                          allowNegativeValue={false}
                          onValueChange={(value, name) =>
                            updateJobInfoSalary({
                              target: { value },
                            })
                          }
                          disabled={state.typeOfSalary !== "Percentage"}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Status
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          value={jobInfo.status}
                          onChange={updateJobInfoStatus}
                        >
                          <option>Choose...</option>
                          <option value="Internship">Internship</option>
                          <option value="Probation">Probation</option>
                          <option value="Trainee">Trainee</option>
                          <option value="Confirmed">Confirmed</option>
                        </Form.Control>
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Salary Basis
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          value={jobInfo.salaryBasis}
                          onChange={updateJobInfoSalaryBasis}
                        >
                          <option>Choose...</option>
                          <option value="Hourly">Hourly</option>
                          <option value="Daily">Daily</option>
                          <option value="Daily">Weekly</option>
                          <option value="Monthly">Monthly</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Contractor
                      </Form.Label>
                      <Col sm={4}>
                        <div
                          className="form-check"
                          style={{
                            height: "44px",
                            padding: "6px 0",
                            display: "inline-block",
                          }}
                        >
                          <input
                            style={{ height: "30px", width: "30px" }}
                            type="checkbox"
                            checked={jobInfo.contract}
                            onChange={updateJobInfoContract}
                          />
                          <label
                            style={{
                              margin: "10px",
                              verticalAlign: "bottom",
                            }}
                          >
                            Check if contract based
                          </label>
                        </div>
                      </Col>
                      {jobInfo.contract && (
                        <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                          Contract Type
                        </Form.Label>
                      )}
                      {jobInfo.contract && (
                        <Col sm={4}>
                          <Form.Control
                            as="select"
                            value={jobInfo.contractType}
                            onChange={updateJobInfoContractType}
                          >
                            <option value="">Choose...</option>
                            {contracts.length ? (
                              contracts.map((project) => {
                                return (
                                  <option value={project._id}>
                                    {project.contractName}
                                  </option>
                                );
                              })
                            ) : (
                              <option value="">Choose...</option>
                            )}
                          </Form.Control>
                        </Col>
                      )}
                    </Form.Group>
                    <div className="submit-section">
                      <button
                        className="btn btn-primary submit-btn"
                        data-toggle="modal"
                        data-target="#job_information_modal"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <AddJobGradeModal
              updateJobGradesList={updateJobGradesList}
              isShow={state.show_add_job}
              changeShowJobState={changeShowJobState}
            />
            <AddLocationModal
              updateLocationsList={updateLocationsList}
              isShow={state.show_add_location}
              changeShowLocationState={changeShowLocationState}
            />
          </div>
          {/*<!-- /Personal Info Modal -->*/}

          {/*<!-- Family Info Modal -->*/}
          <div
            id="family_info_modal"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title"> Family Informations</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-scroll">
                      <div className="card-box">
                        <h3 className="card-title">
                          Family Member{" "}
                          <span className="delete-icon p-cursor">
                            <i className="fa fa-trash-o"></i>
                          </span>
                        </h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Name <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Relationship{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Date of birth{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Phone <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-box">
                        <h3 className="card-title">
                          Education Informations{" "}
                          <span className="delete-icon p-cursor">
                            <i className="fa fa-trash-o"></i>
                          </span>
                        </h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Name <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Relationship{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Date of birth{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Phone <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                        </div>
                        <div className="add-more">
                          <span className="p-cursor">
                            <i className="fa fa-plus-circle"></i> Add More
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="submit-section">
                      <button className="btn btn-primary submit-btn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Family Info Modal -->*/}

          {/*<!-- Emergency Contact Modal -->*/}
          <div
            id="emergency_contact_modal"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Emergency Information</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form onSubmit={saveEmergencyInfo}>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Full Name
                      </Form.Label>
                      <Col sm={10}>
                        <Row>
                          <Col sm={4}>
                            <Form.Control
                              placeholder="First name"
                              value={emergencyInfo?.name?.first}
                              onChange={updateEmergencyInfoNameFirst}
                            />
                          </Col>
                          <Col sm={4}>
                            <Form.Control
                              placeholder="Last name"
                              value={emergencyInfo?.name?.last}
                              onChange={updateEmergencyInfoNameLast}
                            />
                          </Col>
                          <Col sm={4}>
                            <Form.Control
                              placeholder="Middle Initial"
                              value={emergencyInfo?.name?.middle}
                              onChange={updateEmergencyInfoNameMiddle}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Address
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Row>
                          <Form.Group as={Col} sm={4}>
                            <Form.Control
                              placeholder="Apartment/Unit #"
                              value={emergencyInfo?.address?.apartment}
                              onChange={updateEmergencyInfoAddressApartment}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={8}>
                            <Form.Control
                              placeholder="Street Address"
                              value={emergencyInfo?.address?.street}
                              onChange={updateEmergencyInfoAddressStreet}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} sm={4}>
                            <Form.Control
                              placeholder="City"
                              value={emergencyInfo?.address?.city}
                              onChange={updateEmergencyInfoAddressCity}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={4}>
                            <Form.Control
                              placeholder="State"
                              value={emergencyInfo?.address?.state}
                              onChange={updateEmergencyInfoAddressState}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={4}>
                            <Form.Control
                              placeholder="Zip code"
                              value={emergencyInfo?.address?.zip}
                              onChange={updateEmergencyInfoAddressZip}
                            />
                          </Form.Group>
                        </Form.Row>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Home Phone
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={emergencyInfo?.phone?.primary}
                          onChange={updateEmergencyInfoPhonePrimary}
                        />
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Alternate Phone
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={emergencyInfo?.phone?.secondary}
                          onChange={updateEmergencyInfoPhoneSecondary}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Relationship
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          value={emergencyInfo?.relation}
                          onChange={updateEmergencyInfoRelation}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Guarantor
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Row>
                          <Form.Group as={Col} sm={2}>
                            <Form.Control
                              placeholder="Title"
                              value={emergencyInfo?.guarantor?.title}
                              onChange={updateEmergencyInfoGuarantorTitle}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={5}>
                            <Form.Control
                              placeholder="Guarantor Name"
                              value={emergencyInfo?.guarantor?.name}
                              onChange={updateEmergencyInfoGuarantorName}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={5}>
                            <Form.Control
                              placeholder="Guarantor Phone"
                              value={emergencyInfo?.guarantor?.phone}
                              onChange={updateEmergencyInfoGuarantorPhone}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={12}>
                            <Form.Control
                              placeholder="Guarantor Address"
                              value={emergencyInfo?.guarantor?.address}
                              onChange={updateEmergencyInfoGuarantorAddress}
                            />
                          </Form.Group>
                        </Form.Row>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Referee
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Row>
                          <Form.Group as={Col} sm={2}>
                            <Form.Control
                              placeholder="Title"
                              value={emergencyInfo?.referee?.title}
                              onChange={updateEmergencyInfoRefereeTitle}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={5}>
                            <Form.Control
                              placeholder="Referee Name"
                              value={emergencyInfo?.referee?.name}
                              onChange={updateEmergencyInfoRefereeName}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={5}>
                            <Form.Control
                              placeholder="Referee Phone"
                              value={emergencyInfo?.referee?.phone}
                              onChange={updateEmergencyInfoRefereePhone}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={12}>
                            <Form.Control
                              placeholder="Referee Address"
                              value={emergencyInfo?.referee?.address}
                              onChange={updateEmergencyInfoRefereeAddress}
                            />
                          </Form.Group>
                        </Form.Row>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Employee HMO
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          as="select"
                          value={emergencyInfo?.employeeHMO}
                          onChange={updateEmergencyInfoEmployeeHMO}
                        >
                          <option>Select</option>
                          <option value="add_employeeHMO">Add New</option>
                          <option disabled>__________________</option>
                          {employeeHMOs.map((location) => {
                            return (
                              <option value={location._id}>
                                {location.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Group Life Insurance
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          as="select"
                          value={emergencyInfo?.lifeInsurance}
                          onChange={updateEmergencyInfoLifeInsurance}
                        >
                          <option>Select</option>
                          <option value="add_lifeInsurance">Add New</option>
                          <option disabled>__________________</option>
                          {lifeInsurances.map((lifeInsurance) => {
                            return (
                              <option value={lifeInsurance._id}>
                                {lifeInsurance.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Upload Employee Photo
                      </Form.Label>
                      <Col sm={10}>
                        <div className="file-upload-div">
                          <div className="file-preview">
                            <img
                              style={{ height: "120px", width: "110px" }}
                              src={displayImage}
                              alt="CV"
                            />
                          </div>

                          <div className="upload-btn-wrapper">
                            <button className="custom-upload-btn">
                              Browse
                            </button>
                            <input
                              type="file"
                              label="Upload File"
                              ref={emergencyRef}
                              onChange={updateEmergencyInfoImage}
                            />
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Upload Employee Resume
                      </Form.Label>
                      <Col sm={10}>
                        <div className="file-upload-div">
                          <div className="file-preview">
                            <Document
                              file={displayResume}
                              onLoadSuccess={onDocumentLoadSuccess}
                              noData={
                                <div
                                  style={{
                                    width: "110px",
                                    height: "120px",
                                    border: "1px solid #bebebe",
                                  }}
                                ></div>
                              }
                            >
                              <Page
                                pageNumber={pdfPageNumber}
                                height="120"
                                width="120"
                                className="change-pdf-size"
                              />
                            </Document>
                          </div>

                          <div className="upload-btn-wrapper">
                            <button className="custom-upload-btn">
                              Browse
                            </button>
                            <input
                              type="file"
                              label="Upload File"
                              ref={resumeRef}
                              onChange={updateEmergencyInfoResume}
                            />
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                    <div className="submit-section">
                      <button
                        className="btn btn-primary submit-btn"
                        data-toggle="modal"
                        data-target="#emergency_contact_modal"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                  <AddEmployeeHMOModal
                    updateEmployeeHMOsList={updateEmployeeHMOsList}
                    isShow={state.show_add_employeeHMO}
                    changeShowEmployeeHMOState={changeShowEmployeeHMOState}
                  />
                  <AddLifeInsuranceModal
                    updateLifeInsurancesList={updateLifeInsurancesList}
                    isShow={state.show_add_lifeInsurance}
                    changeShowLifeInsuranceState={changeShowLifeInsuranceState}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Emergency Contact Modal -->*/}

          {/*<!-- Education Modal -->*/}
          <div
            id="education_info"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title"> Education Information</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form onSubmit={saveEducationInfo}>
                    {educationInfo?.map((info, idx) => {
                      return (
                        <Form
                          style={{
                            border: "1px solid #e3e3e3",
                            borderRadius: "5px",
                            padding: "20px 10px",
                            margin: "10px",
                          }}
                        >
                          <Form.Group>Education Information</Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label
                              style={{ fontSize: "14px" }}
                              column
                              sm={2}
                            >
                              Institution
                            </Form.Label>
                            <Col sm={4}>
                              <Form.Control
                                value={info.institution}
                                name="institution"
                                id={"institution" + idx}
                                onChange={(e) =>
                                  handleEducationInfoField(idx)(e)
                                }
                              />
                            </Col>
                            <Form.Label
                              style={{ fontSize: "14px" }}
                              column
                              sm={2}
                            >
                              Subject
                            </Form.Label>
                            <Col sm={4}>
                              <Form.Control
                                value={info.subject}
                                name="subject"
                                id={"subject" + idx}
                                onChange={(e) =>
                                  handleEducationInfoField(idx)(e)
                                }
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label
                              style={{ fontSize: "14px" }}
                              column
                              sm={2}
                            >
                              Starting Date
                            </Form.Label>
                            <Col sm={4}>
                              <div classname="datePicker">
                                <i
                                  class="fa fa-calendar fa-xs calendar-icon"
                                  aria-hidden="true"
                                ></i>
                                <Datepicker
                                  value={info.startDate}
                                  name="startDate"
                                  id={"startDate" + idx}
                                  closeOnSelect={true}
                                  onChange={(e) =>
                                    handleEducationInfoStartField(idx)(e)
                                  }
                                />
                              </div>
                            </Col>
                            <Form.Label
                              style={{ fontSize: "14px" }}
                              column
                              sm={2}
                            >
                              Complete Date
                            </Form.Label>
                            <Col sm={4}>
                              <div classname="datePicker">
                                <i
                                  class="fa fa-calendar fa-xs calendar-icon"
                                  aria-hidden="true"
                                ></i>
                                <Datepicker
                                  value={info.completeDate}
                                  name="completeDate"
                                  id={"completeDate" + idx}
                                  closeOnSelect={true}
                                  onChange={(e) =>
                                    handleEducationInfoCompleteField(idx)(e)
                                  }
                                />
                              </div>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label
                              style={{ fontSize: "14px" }}
                              column
                              sm={2}
                            >
                              Degree
                            </Form.Label>
                            <Col sm={4}>
                              <Form.Control
                                value={info.degree}
                                name="degree"
                                id={"degree" + idx}
                                onChange={(e) =>
                                  handleEducationInfoField(idx)(e)
                                }
                              />
                            </Col>
                            <Form.Label
                              style={{ fontSize: "14px" }}
                              column
                              sm={2}
                            >
                              Grade
                            </Form.Label>
                            <Col sm={4}>
                              <Form.Control
                                value={info.grade}
                                name="grade"
                                id={"grade" + idx}
                                onChange={(e) =>
                                  handleEducationInfoField(idx)(e)
                                }
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label
                              style={{ fontSize: "14px" }}
                              column
                              sm={2}
                            >
                              Upload Employee Certificate
                            </Form.Label>
                            <Col sm={10}>
                              <div className="file-upload-div">
                                <div className="file-preview">
                                  <Document
                                    file={displayEducationCertificates[idx]}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    noData={
                                      <div
                                        style={{
                                          width: "110px",
                                          height: "120px",
                                          border: "1px solid #bebebe",
                                        }}
                                      ></div>
                                    }
                                  >
                                    <Page
                                      pageNumber={pdfPageNumber}
                                      height="120"
                                      width="120"
                                      className="change-pdf-size"
                                    />
                                  </Document>
                                </div>

                                <div className="upload-btn-wrapper">
                                  <button className="custom-upload-btn">
                                    Browse
                                  </button>
                                  <input
                                    type="file"
                                    label="Upload File"
                                    name="certificate"
                                    ref={eduRef}
                                    onChange={(e) =>
                                      handleEducationInfoFileField(idx)
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>
                        </Form>
                      );
                    })}
                    <div className="add-more">
                      <button
                        className="btn btn-primary"
                        onClick={addMoreEducationInfo}
                      >
                        <i className="fa fa-plus-circle" /> Add More
                      </button>
                    </div>

                    <div className="submit-section">
                      <button
                        className="btn btn-primary submit-btn"
                        data-toggle="modal"
                        data-target="#education_info"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Education Modal -->*/}

          {/*<!-- Experience Modal -->*/}
          <div
            id="experience_info"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Experience Informations</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-scroll">
                      <div className="card-box">
                        <h3 className="card-title">
                          Experience Informations{" "}
                          <span className="delete-icon p-cursor">
                            <i className="fa fa-trash-o"></i>
                          </span>
                        </h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <input
                                type="text"
                                className="form-control floating"
                                value="Digital Devlopment Inc"
                              />
                              <label className="focus-label">
                                Company Name
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <input
                                type="text"
                                className="form-control floating"
                                value="United States"
                              />
                              <label className="focus-label">Location</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <input
                                type="text"
                                className="form-control floating"
                                value="Web Developer"
                              />
                              <label className="focus-label">
                                Job Position
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <div className="cal-icon">
                                <input
                                  type="text"
                                  className="form-control floating datetimepicker"
                                  value="01/07/2007"
                                />
                              </div>
                              <label className="focus-label">Period From</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <div className="cal-icon">
                                <input
                                  type="text"
                                  className="form-control floating datetimepicker"
                                  value="08/06/2018"
                                />
                              </div>
                              <label className="focus-label">Period To</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-box">
                        <h3 className="card-title">
                          Experience Informations{" "}
                          <span className="delete-icon p-cursor">
                            <i className="fa fa-trash-o"></i>
                          </span>
                        </h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <input
                                type="text"
                                className="form-control floating"
                                value="Digital Devlopment Inc"
                              />
                              <label className="focus-label">
                                Company Name
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <input
                                type="text"
                                className="form-control floating"
                                value="United States"
                              />
                              <label className="focus-label">Location</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <input
                                type="text"
                                className="form-control floating"
                                value="Web Developer"
                              />
                              <label className="focus-label">
                                Job Position
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <div className="cal-icon">
                                <input
                                  type="text"
                                  className="form-control floating datetimepicker"
                                  value="01/07/2007"
                                />
                              </div>
                              <label className="focus-label">Period From</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <div className="cal-icon">
                                <input
                                  type="text"
                                  className="form-control floating datetimepicker"
                                  value="08/06/2018"
                                />
                              </div>
                              <label className="focus-label">Period To</label>
                            </div>
                          </div>
                        </div>
                        <div className="add-more">
                          <button className="btn btn-primary">
                            <i className="fa fa-plus-circle"></i> Add More
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="submit-section">
                      <button className="btn btn-primary submit-btn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Experience Modal -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
};

export default Profile;
