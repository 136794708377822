import React from "react";
import Datepicker from "react-datetime";

import { NormalDot } from "../icons";
import { Button } from "react-bootstrap";
import { useHistory, } from "react-router-dom";
import { URLS } from "../urls";

const Activities = () => {
  const history = useHistory();

  const activities = [
    {
      time: "Today, 10:30 - 11:00",
      details: "Monthly Project Discussion and Reports",
    },
    {
      time: "Today, 10:30 - 11:00",
      details: "Monthly Project Discussion and Reports",
    },
  ];

  return (
    <div className="info activities-dashboard">
      <div>
        <Datepicker
          // value={personalInfo.birth}
          closeOnSelect={false}
          // onChange={this.updatePersonalInfoBirth.bind(this)}
          open={true}
          className="calender-class"
          input={false}
        />
      </div>
      <div className="main-activity">
        <div className="activity-line"></div>
        <div>
          <p className="activity-text">Activities</p>

          <div>
            {activities.map((el) => (
              <div className="activity-box mb-2">
                <div className="d-flex align-items-baseline">
                  <NormalDot />
                  <div className="mx-2">
                    <div className="text-muted activity-box-time">
                      {el?.time}
                    </div>
                    <div className="activity-details">{el?.details}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="activity-foot d-flex justify-content-center">
            <Button variant="" onClick={() => history.push(URLS.calendar)}>View More Events</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activities;
