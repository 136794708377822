import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { URLS } from "./urls";
import { useCookie } from "../utils";
import SentToAccount from "./salary-summary/SendToAccount";
import currency from "currency.js";

export function InforModal(
  {
    show,
    handleClose,
    link,
    description,
    sendTitle,
    secondLink,
    secondTitle,
    payrollDate,
    employeeType,
    thirdTitle,
  },
  props
) {
  const branch_id = useCookie("branch_id");

  const [isLoading, setIsLoading] = useState(false);
  const [showApproval, onShowApproval] = useState(false);
  const [totalNetpay, setTotalNetPay] = useState(0);
  const [deductionItems, setDeductionItems] = useState([]);

  async function getPayroll(month, year) {
    try {
      setIsLoading(true);
      const token = "dummy token";
      const payrollResonse = await fetch(
        `${URLS.backendPayroll}/get-payroll/${branch_id}?payrollMonth=${month}&payrollYear=${year}&employeeType=${employeeType}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      const payrollResult = await payrollResonse.json();
      const { payroll } = payrollResult;
      if (payroll.length > 0) {
        const totalAmount = payroll
          ?.map((el) => el.netPay)
          .reduce((a, b) => a + b, 0);

        setTotalNetPay(totalAmount);

        const deductions = payroll?.map((el) => el.deductions).flat();

        const deducts = [];

        deductions.forEach((deduction) => {
          if (deduction.groupName) {
            deducts.push({
              name: deduction.groupName,
              amount: deduction.total,
            });
          } else {
            deducts.push({ name: deduction.name, amount: deduction.total });
          }
        });

        let summed = deducts.reduce((acc, item) => {
          // If the name already exists, add the amount, otherwise set the initial amount
          if (acc[item.name]) {
            acc[item.name] += item.amount;
          } else {
            acc[item.name] = item.amount;
          }
          return acc;
        }, {});

        summed = Object.entries(summed).map(([key, value]) => ({
          name: key,
          amount: value,
        })); // Convert back to array

        setDeductionItems(summed);
      } else {
        setTotalNetPay(0);
        // setEmployeeType("All");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getPayroll(payrollDate.payrollMonth, payrollDate.payrollYear);
    }

    return () => {
      isMounted = false;
    };
  }, [payrollDate]);

  return (
    <Modal
      {...props}
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{description}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <div className="mb-4"></div>

          <Link to={link} className="btn btn-primary text-center">
            {sendTitle ? sendTitle : "Click"}
          </Link>

          {secondLink && (
            <Link to={secondLink} className="btn btn-primary text-center mx-4">
              {secondTitle ? secondTitle : "Click"}
            </Link>
          )}
          {thirdTitle && (
            <button
              className="btn btn-primary"
              onClick={() => onShowApproval(true)}
            >
              {isLoading ? "Loading..." : thirdTitle}
            </button>
          )}
        </div>
      </Modal.Body>

      {showApproval && (
        <SentToAccount
          payrollDate={payrollDate}
          Amount={currency(totalNetpay, {
            symbol: "",
          }).format()}
          employeeType={employeeType}
          deductionItems={deductionItems}
          show={showApproval}
          onShow={() => onShowApproval(false)}
        />
      )}
    </Modal>
  );
}
