/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import TopBar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import "../../assets/scss/job.css";
import { URLS } from "../urls";
import { useHistory } from "react-router-dom";
import column from "../../assets/img/Columns.svg";
import Pipeline from "../../assets/img/Pipeline.svg";
import columnActive from "../../assets/img/column-unactive.svg";
import PipelineActive from "../../assets/img/Pipeline-active.svg";
import freelancing from "../../assets/img/Freelance.svg";
import employee from "../../assets/img/Employee.svg";
import internship from "../../assets/img/Internship.svg";
import plus from "../../assets/img/Plus.svg";
import PipelineView from "./PipelineView";
import { fetchActionsUtil } from "../../utils";
import ModalLoader from "../ModalLoader";
import DisplayTable from "./displayTable";
import DisplayStatics from "./displayStatics";
import GoBack from "../goBack";
import { toast } from "react-toastify";

const JobApplicants = (props) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    job: {},
    departments: [],
    applications: [],
    activeView: "list", // Default view set to "list"
  });

  const token = "dummy token";

  const { applications, job, activeView } = state;

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const urlSplit = window.location.pathname.split("/");
      const id = urlSplit.length > 3 ? urlSplit[3] : null;

      if (id) {
        const jobData = await fetchActionsUtil(
          `${URLS.backendApplications}/getByJobId/${id}`,
          "GET",
          token
        );

        const staticsData = await fetchActionsUtil(
          `${URLS.backendApplications}/get-Statics/${id}`,
          "GET",
          token
        );

        const [jobResult, staticsResult] = await Promise.all([
          jobData,
          staticsData,
        ]);
        // console.log({ staticsResult });

        setState((el) => ({
          ...el,
          applications: jobResult.applications,
          job: staticsResult,
        }));
      } else {
        history.goBack(); // Goes back to the previous page
      }
    } catch (error) {
      console.log(error);

      history.goBack(); // Goes back to the previous page
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handleToggle = (view) => {
    setState((el) => ({ ...el, activeView: view }));
  };

  const statistics = [
    {
      icon: employee,
      jobName: "vacancies",
      name: "Total Applications",
      typeName: "Vacancies",
    },
    {
      icon: freelancing,
      jobName: "shortlistedEmployee",
      name: " Shortlisted Candidates",
      typeName: "Candidates",
    },
    {
      icon: internship,
      jobName: "rejectedEmployee",
      name: "Rejected Candidates",
      typeName: "Candidates",
    },
    // {
    //   icon: internship,
    //   jobName: "pendingEmployee",
    //   name: "Pending Candidates",
    //   typeName: "Candidates",
    // },
  ];

  const changeButton = ({ name, img, alt, toggle }) => {
    return (
      <div
        className={`box_left-wrapper ${
          activeView === toggle ? "blue-color" : "gray_color"
        }`}
        onClick={() => handleToggle(toggle)}
      >
        <img src={img} alt={alt} />
        <p
          className={`pb-0 ${activeView === toggle ? "p-color" : "gray_color"}`}
        >
          {name}
        </p>
      </div>
    );
  };

  const changeButtonData = [
    {
      name: " List",
      img: `${activeView === "list" ? column : columnActive}`,
      alt: "column img",
      toggle: "list",
    },
    {
      name: "Pipeline",
      img: `${activeView === "pipeline" ? PipelineActive : Pipeline}`,
      alt: "pipeline img",
      toggle: "pipeline",
    },
  ];

  const updateCandidateStatus = async ({ _id, status }) => {
    try {
      setIsLoading(true);
      await fetchActionsUtil(
        `${URLS.backendApplications}/updateCandidateStatus`,
        "POST",
        token,
        { _id, status }
      );

      await fetchData();
      toast.success("Shortlisted");
    } catch (error) {
      console.log(error);
      toast.error("Error !!!");
    } finally {
      setIsLoading(false);
    }
  };

  const dropDownSelect = [
    { name: "Schedule Interview", icon: "fa fa-clock-o m-r-5" },
    {
      name: "ShortList Candidate",
      icon: "fa fa-user-circle-o m-r-5",
      action: updateCandidateStatus,
      status: "shortlisted",
    },
    {
      name: "Reject Candidate",
      icon: "fa fa-ban m-r-5",
      action: updateCandidateStatus,
      status: "rejected",
    },
  ];

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <TopBar />
        <Sidebar />
        <div className="page-wrapper">
          <div className="top_header bg-white w-100 p-2">
            <div className="content container-fluid p-3">
              <div className="content_header_wrapper d-flex align-items-center justify-content-between">
                <div className="header_wrapper">
                  <GoBack />
                  <div>
                    <h3 className="other_det_1 pb-0">{job.jobTitle}</h3>
                    <p className="grey-xl pb-0">All Employee status and data</p>
                  </div>
                </div>

                <div className="left_wrapper">
                  {changeButtonData.map((el) =>
                    changeButton({
                      name: `${el.name}`,
                      img: `${el.img}`,
                      alt: `${el.alt}`,
                      toggle: `${el.toggle}`,
                    })
                  )}

                  <div className="box_left-wrapper">
                    <button className="btn btn-primary">
                      <img src={plus} alt="plus-svg" className="reload-svg" />{" "}
                      Add Candidates
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 px-3">
            <div className="content container container-fluid">
              <div className="chart_container">
                {statistics.map((el) => (
                  <DisplayStatics
                    icon={el.icon}
                    job={job}
                    jobName={el.jobName}
                    name={el.name}
                    typeName={el.typeName}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="content container-fluid">
            {activeView === "pipeline" ? (
              <PipelineView
                applications={applications}
                dropDownSelect={dropDownSelect}
              />
            ) : (
              <DisplayTable
                applications={applications}
                dropDownSelect={dropDownSelect}
              />
            )}
          </div>
        </div>
      </div>
      <ModalLoader show={isLoading} />
      <div className="sidebar-overlay" data-reff=""></div>
    </div>
  );
};

export default JobApplicants;
